import axiosClient from '~/config/axiosClient';
import { useMutation } from 'react-query';

export type CancelRequest = {
  orderCode: string;
  cancelCode: string;
  cancelDesc: string;
};

const cancelOrder = async (params: CancelRequest) => {
  return await axiosClient.post<any>(`order/cancelOrder`, {
    ...params,
  });
};

export const useOrderCancelMutation = () => {
  return useMutation({ mutationFn: (params: CancelRequest) => cancelOrder(params) });
};
