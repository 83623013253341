import { DownOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Divider, Row } from 'antd';
import React, { useState } from 'react';
import { SCard } from '~/components/commons';
import { useOrderDetailContext } from '~/modules/operate-order/pages/OrderDetail/contexts/OrderDetailContext';
import OrderDetailAction from '~/modules/operate-order/pages/OrderDetail/components/OrderDetailAction';
import CouponOrder from '~/modules/operate-order/pages/OrderDetail/components/OrderDetailWrapper/components/CouponOrder';
import DeliveryInfo from '~/modules/operate-order/pages/OrderDetail/components/OrderDetailWrapper/components/DeliveryInfo';
import GeneralOrderInfo from '~/modules/operate-order/pages/OrderDetail/components/OrderDetailWrapper/components/GeneralOrderInfo';
import OrderInfo from '~/modules/operate-order/pages/OrderDetail/components/OrderDetailWrapper/components/OrderInfo';
import PaymentInfo from '~/modules/operate-order/pages/OrderDetail/components/OrderDetailWrapper/components/PaymentInfo';
import OrderDeliveryShipping from './components/OrderDeliveryShipping';
import { BaseUtils } from '~/ultils/base-utils';
import { findConfigById } from '~/ultils/config-utils';

export const EMPTY = <span className="text-xs">---</span>;

const DEFAULT_SHOW_WARNING = 2;

const OrderDetailWrapper: React.FC = () => {
  const { orderDetail } = useOrderDetailContext();
  const { header } = orderDetail || {};
  const { warnings } = header || {};
  const { fulfillError } = orderDetail?.header || {};
  const hasError = Boolean(fulfillError?.type);

  const displayFulfillError = findConfigById(BaseUtils.getConfigWindow()?.fulfillErrorTypes, fulfillError?.type)

  const [showWarningLength, setShowWarning] = useState(DEFAULT_SHOW_WARNING);

  const isFullWarning = showWarningLength === warnings?.length;
  const isShowToggleWarning = (warnings?.length || 0) > DEFAULT_SHOW_WARNING;

  const handleToggleWarning = () => {
    if (isFullWarning) {
      setShowWarning(DEFAULT_SHOW_WARNING);
    } else {
      setShowWarning(warnings?.length as number);
    }
  };

  return (
    <div className="mt-5">
      <Divider />
      <OrderDetailAction />
      <br />
      <>
        {warnings?.slice(0, showWarningLength)?.map((warning) => (
          <>
            <Alert
              className="mb-3"
              message={
                <div className="flex">
                  <span className="font-bold">{warning}</span>
                </div>
              }
              type="warning"
            />
          </>
        ))}
        {isShowToggleWarning && (
          <div className="text-center mb-2" style={{ marginTop: '-8px' }}>
            <Button onClick={handleToggleWarning} type="link">
              <div className="flex items-center">
                <span className="mr-1">
                  {isFullWarning ? 'Thu gọn cảnh báo' : 'Toàn bộ cảnh báo'}
                </span>
                <span>
                  <DownOutlined
                    style={{ transform: isFullWarning ? 'rotate(180deg)' : 'rotate(0)' }}
                  />
                </span>
              </div>
            </Button>
          </div>
        )}
      </>
      {hasError && (
        <Alert
          message={
            <div className="mr-5 pr-5 font-bold text-red-600 ">
              {displayFulfillError}
              {fulfillError?.messages && (
                <ul className="mt-2 pl-0 ml-5 flex flex-col">
                  {fulfillError?.messages?.map((message: any) => (
                    <li className="font-medium">{message}</li>
                  ))}
                </ul>
              )}
            </div>
          }
          type="error"
          className="mb-3"
        />
      )}
      <Row gutter={15}>
        <Col xl={15} lg={24}>
          <SCard rounded className="h-full">
            <OrderInfo />
            <Divider className="my-3" />
            <DeliveryInfo />
            <Divider className="my-3" />
            <OrderDeliveryShipping />
          </SCard>
        </Col>
        <Col xl={9} lg={24}>
          <div className="flex flex-col h-full">
            <GeneralOrderInfo />
            <PaymentInfo />
          </div>
        </Col>

        <Col sm={24}>
          <CouponOrder />
        </Col>
      </Row>
    </div>
  );
};

export default OrderDetailWrapper;
