import TableAntCustom from '~/components/antd/ITable/TableAntCustom';

const columns = [
  {
    title: '#',
    dataIndex: 'storeCode',
    key: 'storeCode',
  },
  {
    title: 'Tên',
    dataIndex: 'storeName',
    key: 'storeName',
  },
  {
    title: 'SL đơn đã đặt',
    dataIndex: 'numberOrders',
    key: 'numberOrders',
  },
  {
    title: 'SL SP đã đặt',
    dataIndex: 'quantityOrdered',
    key: 'quantityOrdered',
    render: (quantityOrdered: number, record: any) => {
      return <span>{quantityOrdered} {record?.unit?.name}</span> 
    },
  },
  {
    title: 'Tồn kho thực tế',
    dataIndex: 'quantityAvailable',
    key: 'quantityAvailable',
    render: (quantityAvailable: number, record: any) => {
      return <span>{quantityAvailable} {record?.unit?.name}</span> 
    },
  },
];

function InventoryAllocations({ storeDistributions, totalNumberOrders }: any) {

  return (
    <div>
      <TableAntCustom
        pagination={false}
        loading={false}
        columns={columns}
        scroll={{ x: 1000, y: 300 }}
        dataSource={storeDistributions}
      />
    </div>
  );
}

export default InventoryAllocations;
