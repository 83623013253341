import axiosClient from '~/config/axiosClient';
import { useQuery } from 'react-query';

const getOrderStatusCounters = async (params) => {
  console.log(params, "params");
  return await axiosClient.get<OrderListTypes>(`order/getOrderStatusCounters`, {
    params: {
      ...params,
      saleChannels: JSON.stringify(params.saleChannels),
    },
  });
};

export const useGetOrderStatusCounters = (params?: any) => {
  return useQuery(['getOrderStatusCounters', params], () => getOrderStatusCounters(params), {
    enabled: true,
  });
};
