import axiosClient from '~/config/axiosClient';
import { useMutation } from 'react-query';

const allowPrintBill = async (orderCode: any) => {
  return await axiosClient.post<any>(`order/allowPrintBill`, {
    orderCode,
  });
};

export const useAllowPrintBill = () => {
  return useMutation({
    mutationFn: (orderCode?: number) => allowPrintBill(orderCode),
  });
};
