import { Button, Col, Form, Input, Modal, Row, Select, Tag } from 'antd';
import { useEffect } from 'react';
import { StoreSelection } from '~/components/commons';
import { useGlobalContext } from '~/contexts/GlobalContext';
import { useWatch } from 'antd/lib/form/Form';

const EditForm = ({ isLoading, onClose, visible, employee, onSave }) => {
  const [form] = Form.useForm();

  const { employeePermissionActions, employeeRoles, stores } = useGlobalContext() as any;

  const roleSelected = useWatch('role', form);
  const isActiveStoreSelection = ['STORE_MANAGER', 'STORE', 'ADMIN'].includes(roleSelected);

  useEffect(() => {
    if (!isActiveStoreSelection) {
      form?.setFieldsValue({ storeCode: null });
    }
  }, [form, isActiveStoreSelection]);

  const cusotmizedEmployeeRoles = employeeRoles?.filter(({ id }) => {
    return ['DEFAULT', 'CS', 'STORE_MANAGER', 'STORE'].includes(id);
  });

  useEffect(() => {
    console.log(employee, 'employee');
    form.setFieldsValue({ ...employee });
  }, [employee, form]);

  return (
    <>
      <Modal
        title={
          <h4>
            Chỉnh sửa nhân viên - <Tag>{employee.name}</Tag>
          </h4>
        }
        onCancel={onClose}
        visible={visible}
        footer={false}
        width={550}
        maskClosable={false}
        bodyStyle={{ padding: '15px 15px' }}
      >
        <Form
          form={form}
          onFinish={(values) => onSave({ ...values, id: employee.id })}
          layout="horizontal"
          labelAlign="left"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
        >
          <Row>
            <Col span={24}>
              <div className="w-100 flex items-center gap-10 px-2">
                <Form.Item name="phone" label="SĐT" style={{ width: '100%' }}>
                  <Input className=" rounded" />
                </Form.Item>
              </div>
            </Col>
            <Col span={24}>
              <div className="w-100 flex items-center gap-10 px-2">
                <Form.Item name="teleId" label="TeleId" style={{ width: '100%' }}>
                  <Input className=" rounded" />
                </Form.Item>
              </div>
            </Col>
            <Col span={24}>
              <div className="w-100 flex items-center gap-10 px-2">
                <Form.Item name="role" label="Quyền" style={{ width: '100%' }}>
                  <Select
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    fieldNames={{
                      value: 'id',
                      label: 'name',
                    }}
                    options={cusotmizedEmployeeRoles}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={24}>
              <div className="w-100 flex items-center gap-10 px-2">
                <Form.Item name="storeCode" label="Siêu thị" style={{ width: '100%' }}>
                  <StoreSelection stores={stores} disabled={!isActiveStoreSelection} />
                </Form.Item>
              </div>
            </Col>
            <Col span={24}>
              <div className="w-100 flex items-center gap-10 px-2">
                <Form.Item name="tenants" label="Tenants" style={{ width: '100%' }}>
                  <Select
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    mode="multiple"
                    options={[
                      {
                        label: 'CPG',
                        value: 'CPG',
                      },
                      {
                        label: 'KINGFOOD',
                        value: 'KINGFOOD',
                      },
                    ]}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={24}>
              <div className="w-100 flex items-center gap-10 px-2">
                <Form.Item name="status" label="Trạng thái" style={{ width: '100%' }}>
                  <Select
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    defaultValue="ACTIVE"
                    options={[
                      {
                        label: 'Hoạt động',
                        value: 'ACTIVE',
                      },
                      {
                        label: 'Tạm ngưng',
                        value: 'INACTIVE',
                      },
                    ]}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={24}>
              <div className="w-100 flex items-center gap-10 px-2">
                <Form.Item name="permissionActions" label="Permission" style={{ width: '100%' }}>
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    fieldNames={{
                      value: 'id',
                      label: 'name',
                    }}
                    options={employeePermissionActions}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>

          <div className="flex gap-2 justify-end  px-2 ml-auto">
            <Button onClick={onClose}>Trở lại</Button>
            <Button loading={isLoading} htmlType="submit" type="primary">
              Lưu
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default EditForm;
