import axiosClient from '~/config/axiosClient';
import { useMutation } from 'react-query';
import { PackageSize } from '~/contants/order';

interface BookShipperRequest {
  orderCode: string;
  packageSize: PackageSize;
}

const bookShipper = async (params: BookShipperRequest) => {
  return await axiosClient.post<any>(`order/bookShipper`, {
    ...params,
  });
};

export const useOrderBookShipperMutation = () => {
  return useMutation({ mutationFn: (params: BookShipperRequest) => bookShipper(params) });
};
