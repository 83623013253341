import { Col, Divider, Row, Tag, Tooltip } from 'antd';
import React, { useMemo } from 'react';
import { SCard } from '~/components/commons';
import CurrencyFormat from '~/components/commons/CurrencyFormat';
import {
  PaymentMethod,
  PaymentStatusText,
  RefundStatus,
} from '~/contants/order';
import { useOrderDetailContext } from '~/modules/operate-order/pages/OrderDetail/contexts/OrderDetailContext';
import { useOrderDetailModalContext } from '~/modules/operate-order/pages/OrderDetail/contexts/OrderDetailModalContext';
import { useOrderProductContext } from '~/modules/operate-order/pages/OrderDetail/contexts/OrderProductDetailContext';
import { Payment, Voucher } from '~/types/orderDetailType';
import { BaseUtils } from '~/ultils/base-utils';
import { calcTotalPriceOrder } from '~/ultils/order-utils';

const PaymentInfo: React.FC = () => {
  const { isShowRefundForm, onSetShowRefundForm } = useOrderDetailModalContext();

  const { products: orderProducts } = useOrderProductContext();
  const { orderDetail } = useOrderDetailContext();
  const { header } = orderDetail || {};
  const { refund, incurredPayment, payment, codPenceExchange, extraPayments } = header || {};
  const { penceAmount, pointAmount } = codPenceExchange || {};
  const { type, amount: amountIncurredPayment } = incurredPayment || {};
  const { method } = payment || {};

  const { vouchers, promotions } = header || {};

  const isCompleteRefund = refund?.status === RefundStatus.COMPLETED;

  const shouldShowBtnRefund = type === 'REFUND';

  const totalProduct = useMemo(() => {
    return Math.round(
      orderProducts?.reduce(
        (previousValue: any, currentValue: any) => previousValue + currentValue.quantity,
        0
      )
    );
  }, [orderProducts]);

  const totalPrice = useMemo(() => {
    return calcTotalPriceOrder(orderProducts);
  }, [orderProducts]);

  const shippingPrice = header?.shipping?.priceAmount || 0;
  const shippingDiscount = header?.shipping?.discountAmount   || 0;

  let renderIncurredPayment: any = useMemo(() => {
    let arr: any = [];
    if (type === 'COD') {
      arr.push({
        label: 'Tiền khách trả thêm',
        amount:
          type === 'COD' ? Number(amountIncurredPayment || 0) + Number(penceAmount || 0) : null,
      });
    }
    if (type === 'REFUND') {
      arr.push({
        label: 'Số tiền hoàn trả khách',
        amount: type === 'REFUND' ? amountIncurredPayment : null,
      });
    }

    if (method === PaymentMethod.CASH_ON_DELIVERY) {
      arr?.splice(0, 1);
      return arr;
    }
    return arr;
  }, [amountIncurredPayment, method, penceAmount, type]);

  const totalDiscountOrder = useMemo(() => {
    return (
      [...(promotions || []), ...(vouchers || [])]?.reduce(
        (previousValue: any, currentValue: any) => {
          return previousValue + Number(currentValue.discountAmount || 0);
        },
        0
      ) || 0
    );
  }, [promotions, vouchers]);

  const totalPriceWithVoucher = useMemo(() => {
    return Number(totalPrice) - Number(totalDiscountOrder || 0) > 0
      ? Number(totalPrice) - Number(totalDiscountOrder || 0)
      : 0;
  }, [totalPrice, totalDiscountOrder]);

  const totalShiping = useMemo(() => {
    return Number(shippingPrice) - Number(shippingDiscount || 0);
  }, [shippingDiscount, shippingPrice]);

  const amountCaculator = useMemo(() => {
    return totalPriceWithVoucher + totalShiping;
  }, [totalPriceWithVoucher, totalShiping]);

  const totalExtraPayment = useMemo(() => {
    return extraPayments?.reduce((previousValue: any, currentValue: any) => {
      return previousValue + Number(currentValue?.amount || 0);
    }, 0);
  }, [extraPayments]) || 0;

  const amountCustomerNeedToPay = useMemo(() => {
    return amountCaculator - totalExtraPayment;
  }, [amountCaculator, totalExtraPayment]);

  const customizedTitle = useMemo(() => {
    if (isCompleteRefund) {
      if (refund?.method === "MEMBERSHIP_POINT") {
        return `Đã hoàn ${BaseUtils.formatNumber(refund.memberPointAmount || 0)} điểm`;
      }

      return `Đã hoàn tiền ${BaseUtils.formatNumber(refund?.cashAmount || 0)}đ `;
    }
    return 'Chưa hoàn tiền';
  }, [isCompleteRefund, refund?.cashAmount, refund?.memberPointAmount, refund?.method]);

  return (
    <SCard className="mt-3 pb-5 flex-grow" rounded>
      <div className="mt-2 pb-2">
        <div className="flex justify-between gap-1 items-center mb-3">
          <h1 className="font-bold mb-0">Thông tin thanh toán</h1>
          <div className="flex gap-1 flex-wrap items-center justify-end">
            {shouldShowBtnRefund && (
              <div>
                <Tag
                  onClick={() => onSetShowRefundForm?.(!isShowRefundForm)}
                  className="underline cursor-pointer"
                  color={isCompleteRefund ? 'green' : 'red'}
                >
                  {customizedTitle}
                </Tag>
              </div>
            )}
            {method !== PaymentMethod.UNDEFINED && (
              <div>
                {header?.payment?.isPaid ? (
                  <Tag color="green">{PaymentStatusText.paid}</Tag>
                ) : (
                  <Tag color="red">{PaymentStatusText.unpaid}</Tag>
                )}
              </div>
            )}
          </div>
        </div>
        <Row>
          <Col span={24}>
            <div className="flex w-100 justify-between">
              <span className="mr-2 font-medium">Số lượng sản phẩm</span>
              <span className="mr-2">{totalProduct}</span>
            </div>
            <Divider className="my-1" />
          </Col>
          <Col span={24}>
            <div className="flex justify-between">
              <span className="mr-2 font-medium">Tiền hàng</span>
              <span className="mr-2">
                <CurrencyFormat number={totalPrice} />
              </span>
            </div>
          </Col>
          {vouchers?.map((voucher: Voucher) => (
            <Col span={24}>
              <div className="flex justify-between">
                <div className="mr-2 font-medium gap-2 flex flex-wrap items-center">
                  <Tooltip title={voucher?.name}>
                    <div className="cursor-pointer truncate text-ellipsis" style={{ maxWidth: 180, textAlign: 'center' }}>
                      {voucher?.name}
                    </div>
                  </Tooltip>
                  <Tag color={voucher.isRefundable ? 'green' : 'red'}>
                    {voucher.isRefundable ? 'Có thể hoàn' : 'Không thể hoàn'}
                  </Tag>
                </div>
                <span className="mr-2 text-danger">
                  <CurrencyFormat number={-Number(voucher?.discountAmount) || 0} />
                </span>
              </div>
            </Col>
          ))}
          {promotions?.map((promotion: Voucher) => (
            <Col span={24}>
              <div className="flex justify-between">
                <span className="mr-2 font-medium">{promotion?.name}</span>
                <span className="mr-2 text-danger">
                  <CurrencyFormat number={-Number(promotion?.discountAmount) || 0} />
                </span>
              </div>
            </Col>
          ))}
          <Col span={24}>
            <Divider className="my-1" />
            <div className="flex justify-between">
              <span className="mr-2 font-medium">Phí vận chuyển</span>
              <span className="mr-2 text-danger">
                <CurrencyFormat number={shippingPrice} />
              </span>
            </div>
          </Col>
          <Col span={24}>
            <div className="flex justify-between">
              <span className="mr-2 font-medium">Giảm giá phí vận chuyển</span>
              <span className="mr-2 text-danger">
                <CurrencyFormat number={-shippingDiscount || 0} />
              </span>
            </div>
          </Col>
          <Divider />
          <div className="flex justify-between w-100">
            <span className="font-bold mr-2 ">Tổng cộng</span>
            <span className="font-medium">
              <CurrencyFormat number={amountCaculator || 0} />
            </span>
          </div>
          {codPenceExchange && Number(penceAmount || 0) > 0 && (
            <div className="flex justify-between w-100">
              <span className="mr-2 ">Thối tiền thừa bằng điểm</span>
              <span className="font-medium">
                ({BaseUtils.formatNumber(pointAmount || 0)} điểm) &nbsp;
                {BaseUtils.formatNumber(penceAmount || 0)} đ
              </span>
            </div>
          )}

          {!shouldShowBtnRefund && (
            <>
              {renderIncurredPayment?.map((obj: any) => (
                <>
                  <Divider className="my-2" />
                  <Tag color="warning" className=" flex items-center ml-auto grow mr-0 my-2 w-100">
                    <div className="flex items-center justify-between w-100">
                      <span className="font-bold">{obj?.label}</span>
                      <span className="font-medium">
                        <CurrencyFormat number={obj?.amount || 0} />
                      </span>
                    </div>
                  </Tag>
                </>
              ))}
            </>
          )}

          {method !== PaymentMethod.CASH_ON_DELIVERY && (
            <>
              <Divider className="my-2" />
              <div className="flex justify-between w-100">
                <span className="font-bold mr-2 ">Khách đã thanh toán</span>
                <span className="font-medium">
                  <CurrencyFormat number={payment?.amount || 0} />
                </span>
              </div>
            </>
          )}
          {extraPayments?.map((payment: Payment) => (
            <div className="flex justify-between w-100">
              <span className="font-bold">{payment?.methodName}</span>
              <span className="font-medium">
                <CurrencyFormat number={payment?.amount || 0} />
              </span>
            </div>
          ))}
          {method === PaymentMethod.CASH_ON_DELIVERY && (
            <div className="flex justify-between w-100">
              <span className="font-bold mr-2 ">Khách cần thanh toán</span>
              <span className="font-medium">
                <CurrencyFormat
                  number={Number(amountCustomerNeedToPay || 0) + Number(penceAmount || 0) || 0}
                />
              </span>
            </div>
          )}
        </Row>
      </div>
    </SCard>
  );
};

export default PaymentInfo;
