import axiosClient from '~/config/axiosClient';
import { useMutation } from 'react-query';
import { OrderDelivery } from '~/types/orderDetailType';

const updateProductItems = async (orderDelivery?: OrderDelivery) => {
  return await axiosClient.post<OrderListTypes>(`/order/updateProductItems`, {
    ...orderDelivery,
  });
};

export const useUpdateProductItems = () => {
  return useMutation({
    mutationFn: (orderDelivery: OrderDelivery) => updateProductItems(orderDelivery),
  });
};
