import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SError } from '~/components/commons';
import Loading from '~/components/loading';
import { PARAM_CREATE_ORDER } from '~/contants/commons';
import { StatusOfOrder } from '~/contants/order';
import { useGlobalContext } from '~/contexts/GlobalContext';
import { useOrderDetailQuery } from '~/queries/orders/useOrderDetailQuery';
import { findConfigById } from '~/ultils/config-utils';
import {
  OrderFormData,
  useDataFormOrderDetail,
} from '~/modules/operate-order/pages/OrderDetail/hooks/useDataFormOrderDetail';
import { OrderDetail } from '~/types/orderDetailType';

type ValueOrderContext = {
  orderStatus?: string;
  orderDetail?: OrderDetail;
  isCreateOrder?: boolean;
  storeCodeSelected?: string;
  onSetStoreSelected?: (storeSelected: any) => void;
} & OrderFormData;

export const OrderDetailContext = React.createContext<ValueOrderContext>({
  orderStatus: StatusOfOrder.NEW,
  orderDetail: {},
});

const initDataOrder: OrderDetail = {
  header: {
    extraPayments: [],
    codAmount: undefined,
    code: '',
    customer: undefined,
    deliveryAddress: undefined,
    discountAmount: undefined,
    employeeId: undefined,
    deliveryTimeRange: undefined,
    id: undefined,
    note: undefined,
    amount: 0,
    orderTime: undefined,
    payment: {},
    payStatus: undefined,
    saleChannel: undefined,
    status: 'NEW',
    statusLogs: undefined,
  },
  delivery: {},
};

const OrderDetailContextProvider: React.FC<any> = ({ children }) => {
  const { type } = useParams();

  const isCreateOrder = type === PARAM_CREATE_ORDER;

  const [hasChange, setHasChange] = useState(false);
  const [details, setDetail] = useState<OrderDetail>({});
  const { code } = useParams();

  const {
    data,
    isError,
    isLoading = false,
    isFetched,
  } = useOrderDetailQuery(code, { enabled: !isCreateOrder });

  useEffect(() => {
    if (hasChange && isFetched) {
      setHasChange(false);
      setDetail({});
      setDetail({ ...data });
    }
  }, [hasChange, isFetched]);

  useEffect(() => {
    setDetail({ ...data });
  }, [data]);

  const { orderStatuses } = useGlobalContext();

  const { actions: dataOrdersActions, orderDetail } = useDataFormOrderDetail(details);

  const orderStatus: any = findConfigById(orderStatuses, orderDetail?.header?.status);

  useEffect(() => {
    if (orderDetail) {
      document.title = `Seedcom | Đơn hàng - #${orderDetail?.header?.code}`;
    }
  }, [orderDetail]);

  const valueContext: ValueOrderContext = useMemo(
    () => ({
      ...dataOrdersActions,
      orderDetail: isCreateOrder ? initDataOrder : orderDetail,
      isCreateOrder,
      orderStatus,
    }),
    [dataOrdersActions, isCreateOrder, orderDetail, orderStatus]
  );

  if (isError) {
    return (
      <div className="w-full h-full text-center flex justify-center items-center">
        <SError />
      </div>
    );
  }

  if (data?.error) {
    return (
      <div className="w-full h-full text-center flex justify-center items-center">
        {data?.error}
      </div>
    );
  }

  if (isLoading && !isCreateOrder) {
    return <Loading />;
  }

  return <OrderDetailContext.Provider value={valueContext}>{children}</OrderDetailContext.Provider>;
};

export default OrderDetailContextProvider;

export const useOrderDetailContext = () => useContext(OrderDetailContext);
