import axiosClient from '~/config/axiosClient';
import { useMutation } from 'react-query';

const addOrderLogByCSCallCustomerFail = async (orderCode: any, note?: string) => {
  return await axiosClient.post<any>(`order/addOrderLogByCSCallCustomerFail`, {
    orderCode,
    note,
  });
};

export const useAddOrderLogByCSCallCustomerFail = () => {
  return useMutation({
    mutationFn: ({orderCode, note}: {orderCode: number, note?: string}) => addOrderLogByCSCallCustomerFail(orderCode, note),
  });
};
