import axiosClient from '~/config/axiosClient';
import { useMutation } from 'react-query';
import { OrderDetail } from '~/types/orderDetailType';

export type VATUpdateRequest = {
  address?: string;
  phone?: string;
  name?: string;
  taxCode?: string;
  email?: string;
  orderCode?: string;
};

const updateOrderVAT = async (req: VATUpdateRequest) => {
  const orderCode = req.orderCode;
  delete req.orderCode;
  const VATJson = {
    ...req,
  };
  return await axiosClient.post<any>(`order/updateOrderVAT`, {
    data: { ...VATJson },
    orderCode,
  });
};

export const useUpdateOrderVAT = () => {
  return useMutation({
    mutationFn: (req: VATUpdateRequest) => updateOrderVAT(req),
  });
};
