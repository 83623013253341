import React, { useContext, useMemo } from 'react';
import useOrderProductDetail from '../hooks/useOrderProductDetail';

type PropsContext = {
  products: any;
  increaseQuantity?: Function;
  decreaseQuantity?: Function;
  onChangeQuantity?: Function;
};

const OrderProductDetailContext = React.createContext<PropsContext>({
  products: [],
});

const OrderProductDetailProvider = ({ children }: { children: React.ReactNode }) => {
  const { products, increaseQuantity, decreaseQuantity, onChangeQuantity } =
    useOrderProductDetail();

  const valueContext: any = useMemo(
    () => ({ products, increaseQuantity, decreaseQuantity, onChangeQuantity }),
    [products]
  );
  return (
    <OrderProductDetailContext.Provider value={valueContext}>
      {children}
    </OrderProductDetailContext.Provider>
  );
};

export default OrderProductDetailProvider;

export const useOrderProductContext = () => useContext(OrderProductDetailContext);
