import { BellOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Select, Tag, Tooltip, Typography, message } from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import IPagination from '~/components/antd/IPagination';
import TableAntCustom from '~/components/antd/ITable/TableAntCustom';
import { SCard } from '~/components/commons';
import { SERVER_PREFIX } from '~/contants/commons';
import { useGlobalContext } from '~/contexts/GlobalContext';
import { usePaging } from '~/hooks/usePaging';
import { useSearchEmployees } from '~/queries/settings/employee/useSearchEmployees';
import { useUpdateEmployee } from '~/queries/settings/employee/useUpdateEmployee';
import { BaseUtils } from '~/ultils/base-utils';
import { findConfigById } from '~/ultils/config-utils';
import EditForm from './EditForm';

const { Paragraph } = Typography;

const columns = ({ onEditEmployee }) => [
  {
    title: 'Tên nhân viên',
    dataIndex: 'name',
    width: 300,
    key: 'name',
    render: (name, { phone, role, username }) => {
      const roleName: any = findConfigById(BaseUtils.getConfigWindow()?.employeeRoles, role);
      const renderRole = role !== 'DEFAULT' ? <Tag color="blue">{roleName}</Tag> : <></>;

      return (
        <div className="flex flex-col gap-1">
          <div className="flex items-center gap-2">
            <span>
              {username?.toUpperCase()} - {name}
            </span>
          </div>
          <div className="flex items-center gap-2">
            {phone && <span className="text-gray-500">{phone}</span>}
            <div>{renderRole}</div>
          </div>
        </div>
      );
    },
  },
  {
    title: 'Công ty',
    dataIndex: 'tenants',
    key: 'tenants',
    width: 150,
    render: (tenants) => {
      const tenantNames = tenants?.map((tenant) => {
        const tenantName: any = findConfigById(
          BaseUtils.getConfigWindow()?.tenants,
          tenant
        );
        return tenantName;
      });
      return <span>{tenantNames?.join(', ')}</span>;
    },
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    key: 'status',
    render: (status) => {
      return <Tag color={status === 'ACTIVE' ? 'green' : 'red'}>{status}</Tag>;
    },
  },
  {
    title: 'Cửa hàng',
    dataIndex: 'storeCode',
    key: 'storeCode',
    width: 300,
    render: (storeCode, { storeName }) => {
      return (
        <div className="flex flex-col">
          <span>{storeCode}</span>
          <Tooltip placement="top" title={storeName}>
            <div
              className="text-xs truncate text-ellipsis"
              style={{ color: 'rgb(131 125 125)', marginTop: 2, maxWidth: 300 }}
            >
              {storeName}
            </div>
          </Tooltip>
        </div>
      );
    },
  },

  {
    title: 'Permission',
    dataIndex: 'permissionActions',
    key: 'permissionActions',
    width: 300,
    render: (permissionActions) => {
      return permissionActions?.map((permissionAction) => {
        const permisionName: any = findConfigById(
          BaseUtils.getConfigWindow()?.employeePermissionActions,
          permissionAction
        );
        return (
          <Tag className="mb-1" color="blue">
            {permisionName}
          </Tag>
        );
      });
    },
  },
  {
    title: 'Last access',
    dataIndex: 'lastAccessedTime',
    key: 'lastAccessedTime',
    width: 270,
    render: (lastAccessedTime) => {
      return moment(lastAccessedTime).format('DD/MM/YYYY HH:mm');
    },
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'createdTime',
    key: 'createdTime',
    render: (createdTime) => {
      return moment(createdTime).format('DD/MM/YYYY');
    },
  },
  {
    title: '#',
    className: 'text-center',
    render: (_, record) => {
      return (
        <div className="flex items-center justify-center">
          <Button
            type="text"
            size="small"
            onClick={() => onEditEmployee(record)}
            icon={<EditOutlined />}
          ></Button>
          {record?.fcmToken && (
            <Button type="text" size="small">
              <Paragraph
                copyable={{
                  text: record?.fcmToken,
                  icon: [<BellOutlined key="copy-icon" />, <BellOutlined key="copied-icon" />],
                  tooltips: ['Copy token', 'Đã copy xong'],
                }}
              ></Paragraph>
            </Button>
          )}
        </div>
      );
    },
  },
];

const EmployeeSetting = () => {
  const [keyword, setKeyword] = useState('');
  const [visible, setVisible] = useState(false);
  const [employee, setEmployee] = useState({});
  const [role, setRole] = useState();

  const { employeeRoles } = useGlobalContext();

  const { mutate: updateEmployee, isSuccess, isLoading, data } = useUpdateEmployee();

  useEffect(() => {
    if (data?.error) {
      message.error(SERVER_PREFIX + data?.error);
    } else if (isSuccess) {
      message.success('Cập nhật nhân viên thành công');
      setVisible(false);
      refetch();
    }
  }, [isSuccess]);

  const {
    actions: { onChangePagination },
    states: { sizer, currentPage },
  } = usePaging({ sizer: 10 });

  const filters = {
    pageIndex: currentPage,
    limit: sizer,
    keyword,
    role,
  };

  const {
    data: response,
    isLoading: loadingEmployee,
    refetch,
  } = useSearchEmployees({ ...filters });

  useEffect(() => {
    refetch();
  }, [currentPage, keyword, role]);

  const handelEditEmployee = (record) => {
    setVisible(true);
    setEmployee(record);
  };

  const handleSaveEmployee = useCallback(
    (employee) => {
      updateEmployee(employee);
    },
    [updateEmployee]
  );

  const handleSelectRole = (value) => {
    setRole(value);
  };

  const dataEmployees = response?.list || [];
  return (
    <>
      <SCard className="p-5" rounded>
        <div className="flex justify-end gap-2 mb-4">
          <Select
            allowClear
            style={{
              width: '300px',
            }}
            fieldNames={{
              value: 'id',
              label: 'name',
            }}
            options={employeeRoles}
            placeholder="Chọn Role"
            onChange={handleSelectRole}
          />
          <Input
            allowClear
            onChange={(e: any) => {
              setKeyword(e.target.value);
            }}
            style={{ width: 400 }}
            suffix={<SearchOutlined />}
            placeholder="Tìm theo tên, SDT"
          />
        </div>
        <TableAntCustom
          iKey="code"
          dataSource={dataEmployees || []}
          columns={columns({
            onEditEmployee: handelEditEmployee,
          })}
          loading={loadingEmployee}
          pagination={false}
        ></TableAntCustom>
        <IPagination
          hiddenPerPage
          size={sizer}
          total={response?.total || 0}
          current={currentPage}
          onChange={onChangePagination}
        />
      </SCard>
      {/* Modal */}
      <EditForm
        visible={visible}
        employee={employee}
        onSave={handleSaveEmployee}
        isLoading={isLoading}
        onClose={() => setVisible(false)}
      />
    </>
  );
};

export default EmployeeSetting;
