import React, { createContext, useContext, useMemo } from 'react';
import useOrderDetailModal, {
  OrderDetailModalAction,
  OrderDetailModalState,
} from '../hooks/useOrderDetailModal';

type OrderDetailModalContextValue = OrderDetailModalState & OrderDetailModalAction;

const OrderDetailModalContext = createContext<OrderDetailModalContextValue>({});

type Props = {
  children: React.ReactNode;
};

const OrderDetailModalProvider: React.FC<Props> = ({ children }) => {
  const values = useOrderDetailModal();

  const valueContext: OrderDetailModalContextValue = useMemo(
    () => ({
      ...values,
    }),
    [values]
  );

  return (
    <OrderDetailModalContext.Provider value={valueContext}>
      {children}
    </OrderDetailModalContext.Provider>
  );
};

export default OrderDetailModalProvider;

export const useOrderDetailModalContext = () => useContext(OrderDetailModalContext);
