import { useMutation } from 'react-query';
import axiosClient from '~/config/axiosClient';

export type RequestSendZNSMessage = {
  template: "LATE_DELIVERY" | "CONTACT_CUSTOMER_FAIL"; // Số lượng voucher
  orderCode: string;
};

const sendZNSMessage = async (sorryVoucher: RequestSendZNSMessage) => {
  return await axiosClient.post<any>(`order/sendZNSMessage`, {
    ...sorryVoucher,
  });
};

export const useSendZNSMessage = () => {
  return useMutation({
    mutationFn: (sorryVoucher: RequestSendZNSMessage) => sendZNSMessage(sorryVoucher),
  });
};
