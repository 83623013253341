import axiosClient from '~/config/axiosClient';
import { useMutation } from 'react-query';

const updateOrderByPickedItems = async (orderCode: any) => {
  return await axiosClient.post<any>(`order/updateOrderByPickedItems`, {
    orderCode,
  });
};

export const useUpdateOrderByPickedItems = () => {
  return useMutation({
    mutationFn: (orderCode?: number) => updateOrderByPickedItems(orderCode),
  });
};
