import { useQuery } from 'react-query';
import axiosClient from '~/config/axiosClient';

const getDetailOrder = async (code?: string) => {
  if (!code) return;
  const params = {
    orderCode: code,
  };
  return await axiosClient.get(`order/getOrderDetail`, { params });
};

export const SHARE_SECRET_KEY = 'enYf9COjcsElHnt4E5gU6by';

const getDetailShareOrder = async (
  code?: string,
) => {
  if (!code) return;
  const params = {
    orderCode: code,
    secretKey: SHARE_SECRET_KEY,
  };
  return await axiosClient.get(`share/getOrderDetail`, { params });
};

export const useOrderDetailQuery = (code?: string, config = { enabled: true }) => {
  return useQuery(['orderDetail'], () => getDetailOrder(code), {
    ...config,
  });
};

export const useOrderShareDetailQuery = (
  code?: string,
  config = { enabled: true }
) => {
  return useQuery(['orderShareDetail', code], () => getDetailShareOrder(code), {
    ...config,
  });
};
