import React, { createContext, useContext, useMemo } from 'react';
import { OrderDetailDrawerType, useOrderDetailDrawer } from '../hooks/useOrderDetailDrawer';

const OrderDetailDrawerContext = createContext<OrderDetailDrawerType>({});

type Props = {
  children: React.ReactNode;
};

const OrderDetailDrawerProvider: React.FC<Props> = ({ children }) => {
  const { actions: dataPopupActions, valueState: popupValueState } = useOrderDetailDrawer();

  const valueContext = useMemo(
    () => ({
      ...dataPopupActions,
      ...popupValueState,
    }),
    [dataPopupActions, popupValueState]
  );

  return (
    <OrderDetailDrawerContext.Provider value={valueContext}>
      {children}
    </OrderDetailDrawerContext.Provider>
  );
};

export default OrderDetailDrawerProvider;

export const useOrderDetailDrawerContext = () => useContext(OrderDetailDrawerContext);
