import { Image, Modal, Tabs } from 'antd';
import TableAntCustom from '~/components/antd/ITable/TableAntCustom';
import { fallbackImage } from '~/contants/commons';

const columns = [
  {
    title: '#',
    dataIndex: 'image',
    key: 'image',
    render: (image: string) => (
      <Image
        wrapperStyle={{ maxWidth: '50px' }}
        fallback={fallbackImage}
        className="mt-3"
        preview={false}
        src={image || ''}
      />
    ),
  },
  {
    title: 'Barcode',
    dataIndex: 'barcode',
    key: 'barcode',
  },
  {
    title: 'Tên SP',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'SL tront combo',
    dataIndex: 'quantityAvailable',
    key: 'quantityAvailable',
  },
  {
    title: 'SL SP đã đặt',
    dataIndex: 'quantityOrdered',
    key: 'quantityOrdered',
    render: (quantityOrdered: number, record: any) => {
      return <span>{quantityOrdered} {record?.unit?.name}</span> 
    },
  },
];

function VariantElementDistributions({ mapVariantElementDistributions }: any) {
  return (
    <>
      {Boolean(Object.keys(mapVariantElementDistributions || {}).length) && (
        <Tabs>
          {Object.keys(mapVariantElementDistributions || {}).map((key: string) => {
            return (
              <Tabs.TabPane key={key} tab={key}>
                <TableAntCustom
                  pagination={false}
                  loading={false}
                  columns={columns}
                  scroll={{ x: 1000, y: 300 }}
                  dataSource={mapVariantElementDistributions[key]}
                />
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      )}
      {!Object.keys(mapVariantElementDistributions || {}).length && (
        <TableAntCustom pagination={false} loading={false} columns={columns} dataSource={[]} />
      )}
    </>
  );
}

export default VariantElementDistributions;
