import axiosClient from '~/config/axiosClient';
import { useMutation } from 'react-query';

const orderConfirm = async (orderCode: any) => {
  return await axiosClient.post<any>(`order/confirmOrder`, {
    orderCode,
  });
};

export const useOrderConfirmMutation = () => {
  return useMutation({ mutationFn: (orderCode?: number) => orderConfirm(orderCode) });
};
