import axiosClient from '~/config/axiosClient';
import { useMutation } from 'react-query';

const requestCustomerMakeExtraPayment = async (params?: any) => {
  return await axiosClient.post<OrderListTypes>(`order/requestCustomerMakeExtraPayment`, {
    ...params,
  });
};

export const useRequestCustomerMakeExtraPayment = () => {
  return useMutation({
    mutationFn: ({ orderCode }: { orderCode: string }) =>
      requestCustomerMakeExtraPayment({ orderCode }),
  });
};
