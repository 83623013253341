import { useCallback, useEffect, useState } from 'react';
import { OrderDetail } from '~/types/orderDetailType';

export type OrderFormData = {
  setChannel?: (saleChannel: number) => void;
  onSelectedPaymentMethod?: (paymentMethod: number) => void;
  isLoadingSubmit?: boolean;
  onChangeNote?: (note?: any) => void;
};

export const useDataFormOrderDetail = (orderDetail?: OrderDetail) => {
  const [orderDetailTransform, setOrderDetailTransform] = useState<OrderDetail>({ ...orderDetail });

  useEffect(() => {
    setOrderDetailTransform({ ...orderDetail });
  }, [orderDetail]);

  const setChannel = useCallback((saleChannel: number) => {
    setOrderDetailTransform((currentValue) => ({
      ...currentValue,
      saleChannel,
    }));
  }, []);


  const handleSelectedPaymentMethod = useCallback((paymentMethod: number) => {
    setOrderDetailTransform((currentValue) => ({
      ...currentValue,
      paymentMethod,
    }));
  }, []);

  const handleChangeNote = useCallback((event: any) => {
    const { value: note } = event.target;
    setOrderDetailTransform((currentValue) => ({
      ...currentValue,
      note,
    }));
  }, []);


  const actions = {
    setChannel,
    onSelectedPaymentMethod: handleSelectedPaymentMethod,
    onChangeNote: handleChangeNote,
  };

  return {
    actions,
    orderDetail: orderDetailTransform,
  };
};
