import axiosClient from '~/config/axiosClient';
import { useMutation } from 'react-query';

const assignOrders = async (params?: any) => {
  return await axiosClient.post<OrderListTypes>(`order/assignOrders`, {
    ...params,
  });
};

export const useOrdersAssignMutation = () => {
  return useMutation({ mutationFn: (params: any) => assignOrders(params) });
};
