import axiosClient from '~/config/axiosClient';
import { useMutation } from 'react-query';

const removeOrderError = async (orderCode?: string) => {
  return await axiosClient.post<OrderListTypes>(`/order/removeOrderError`, {
    orderCode,
  });
};

export const useRemoveOrderError = () => {
  return useMutation({
    mutationFn: (orderCode: string) => removeOrderError(orderCode),
  });
};
