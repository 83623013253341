import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row, Typography } from 'antd';
import { useEffect } from 'react';

const { Paragraph } = Typography;

const VATForm = ({ isModalOpen, onOk, onCancel, loading, orderDetail }: any) => {
  const { vat } = orderDetail?.header || {};
  const [form] = Form.useForm<{}>();

  useEffect(() => {
    form.setFieldsValue({ ...vat });
  }, [form, vat]);

  return (
    <Modal
      footer={false}
      centered
      visible={isModalOpen}
      width={700}
      onCancel={onCancel}
      closable={false}
      maskClosable={false}
      title="Thông tin VAT"
    >
      <Form
        layout="vertical"
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onOk}
        form={form}
        autoComplete="off"
      >
        <div className="flex flex-col gap-3 items-center">
          <div
            className="absolute"
            style={{
              right: 20,
              top: 15,
            }}
          >
            <Paragraph
              copyable={{
                text: Object.values(vat || {}).join('\n'),
                tooltips: ['Nhấn để copy', 'Copy thành công'],
              }}
              className="mb-0"
            />
          </div>
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item
                name="name"
                label="Tên công ty"
                rules={[{ required: true, message: 'Không được để rỗng' }]}
              >
                <Input placeholder="Nhập tên công ty" className="w-100" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="taxCode"
                label="Mã số thuế"
                rules={[{ required: true, message: 'Không được để rỗng' }]}
              >
                <Input placeholder="Nhập mã số thuế" className="w-100" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="phone"
                label="SĐT"
                rules={[{ required: true, message: 'Không được để rỗng' }]}
              >
                <Input placeholder="Nhập SĐT" className="w-100" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="email"
                label="Email"
                rules={[{ required: true, message: 'Không được để rỗng' }]}
              >
                <Input placeholder="Nhập email" className="w-100" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="address"
                label="Địa chỉ"
                rules={[{ required: true, message: 'Không được để rỗng' }]}
              >
                <Input placeholder="Nhập địa chỉ" className="w-100" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="customerName" label="Tên KH">
                <Input placeholder="Nhập tên KH" className="w-100" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="customerPhone"
                label="SDT KH"
                rules={[{ required: true, message: 'Không được để rỗng' }]}
              >
                <Input placeholder="Nhập SDT KH " className="w-100" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="note" label="Ghi chú">
                <Input placeholder="Nhập ghi chú" className="w-100" />
              </Form.Item>
            </Col>
          </Row>
          <div className="flex justify-center gap-2 mt-5">
            <Button onClick={onCancel}>Trở lại</Button>
            <Button loading={loading} htmlType="submit" type="primary">
              Xác nhận
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default VATForm;
