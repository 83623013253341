import { useCallback, useMemo, useState } from 'react';

export type OrderDetailModalState = {
  isShowRefundForm?: boolean;
};

export type OrderDetailModalAction = {
  onSetShowRefundForm?: (visible: boolean) => void;
};

const useOrderDetailModal = () => {
  const [isShowRefundForm, setShowRefundForm] = useState<boolean>(false);

  const handleSetRefundForm = useCallback((visible: boolean) => {
    setShowRefundForm(visible);
  }, []);

  const valueState: OrderDetailModalState = {
    isShowRefundForm,
  };

  const actions: OrderDetailModalAction = {
    onSetShowRefundForm: handleSetRefundForm,
  };

  return useMemo(
    () => ({
      ...valueState,
      ...actions,
    }),
    [isShowRefundForm]
  );
};

export default useOrderDetailModal;
