import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useUserInfoReset } from '~/hooks/useUserInfo';
import { useLocalStorage } from 'react-use';
import { useAuthorizeIAMMutation } from '~/queries/auth/useAuthorizeIAMMutation';
import { useGetMyProfileQuery } from '~/queries/employee/useGetMyProfileQuery';
import { TOKEN_KEY_STORE } from '~/contants/commons';

declare var window: any;

type Props = {
  children: React.ReactNode;
};

type AuthContextType = {
  setIsAuth?: any;
  isAuth?: boolean;
  users: any;
  setToken?: (value?: any) => void;
  setUsers?: (userInfo: any) => void;
  profileData: {
    loading: boolean;
    data: {
      role?: 'CS' | 'CS_MANAGER';
      id?: string;
      orderAssignSetting?: {
        status?: 'ENABLE' | 'DISABLE';
        types?: Array<'NEW_ORDER' | 'RATING_ORDER'>;
      };
    };
  };
};

export const AuthContext = React.createContext<AuthContextType>({
  isAuth: false,
  users: {},
  profileData: {
    loading: false,
    data: {},
  },
});

const AuthContextProvider: React.FC<Props> = ({ children }): React.ReactElement => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tokenCache = localStorage.getItem('token');
  const [, setToken] = useLocalStorage(TOKEN_KEY_STORE, null);
  const [userInfoCache, setCacheUserInfo] = useLocalStorage('userInfo', null);
  const [isAuth, setIsAuth] = useState(Boolean(tokenCache));
  const [users, setUsers] = useState<any>(userInfoCache || {});
  const { expired, resetUser } = useUserInfoReset();

  const { data: profile, isLoading: loadingProfile } = useGetMyProfileQuery(
    {},
    {
      enabled: Boolean(isAuth),
    }
  );

  const onSuccess = (values: any) => {
    if (values && !values.error) {
      setCacheUserInfo({ ...values });
      setUsers({ ...values });
      setToken(values.zas);
      window.token = values.zas;
    }
  };

  const { mutate: fetchAuthorizeIAM, isLoading } = useAuthorizeIAMMutation({ onSuccess });

  useEffect(() => {
    const token = searchParams.get('t');
    const signature = searchParams.get('s');
    if (searchParams.has('t') && searchParams.has('s') && token !== tokenCache) {
      setIsAuth(true);
      fetchAuthorizeIAM({ token, signature });
    }
  }, [fetchAuthorizeIAM, searchParams]);

  const checkExpiredSession = () => {
    if (!isAuth) return false;
    try {
      return Number(expired || 0) < moment(new Date()).valueOf();
    } catch {
      return false;
    }
  };

  useEffect(() => {
    const isExpired = checkExpiredSession();
    if (isExpired) {
      resetUser();
      setIsAuth(false);
      navigate('/login');
    }
  }, []);

  const valueContext: AuthContextType = {
    isAuth,
    users,
    setIsAuth,
    setToken,
    setUsers,
    profileData: {
      loading: loadingProfile,
      data: profile,
    },
  };

  if (isLoading) return <>Loading....</>;

  return (
    <AuthContext.Provider value={valueContext}>
      <>{children}</>
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;

export const useAuthContext = () => useContext(AuthContext);
