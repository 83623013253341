import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import { AutoComplete, Input, Select, SelectProps } from 'antd';
import { useDebounce } from 'react-use';
import { BaseUtils } from '~/ultils/base-utils';
import { useEmployeesByKeyWordQuery } from '~/queries/employee/useEmployeesByKeyWordQuery';

const renderItem = (data: any) => {
  return (
    <div className="flex flex-col">
      <span className="font-medium">{data?.name}</span>
      <span className="text-xs text-gray-500">
        {BaseUtils.cleanArray([data?.code, data?.email]).join(' - ')}
      </span>
    </div>
  );
};

type Props = {
  onSelectEmployee?: (value: any, employee: any, option: any) => void;
  filters?: any;
  placeholder?: string;
  allowSearch?: boolean;
  ref?: any;
};

const EmployeeSelection: React.FC<Props> = forwardRef(
  ({ onSelectEmployee, allowSearch = true, placeholder, filters = {} }, ref: any) => {
    const [options, setOptions] = useState<SelectProps<object>['options']>([]);
    const [value, setValue] = useState<string>('');

    console.log(filters, 'filters');

    const [] = useDebounce(
      () => {
        if (value) {
          fetchEmployee();
        }
      },
      500,
      [value]
    );

    const {
      data,
      isFetched,
      isFetching,
      refetch: fetchEmployee,
    } = useEmployeesByKeyWordQuery(
      {
        keyword: value,
        ...filters,
      },
      { enabled: false }
    );

    useEffect(() => {
      fetchEmployee();
    }, []);

    useEffect(() => {
      if (isFetched && !data.length) {
        setOptions([
          {
            value: '',
            label: <span>Không tìm thấy nhân viên</span>,
            email: '',
          },
        ]);
      } else {
        const dataTransform = data?.map((data: any) => ({
          value: data.id,
          label: renderItem(data),
        }));

        setOptions(dataTransform || []);
      }
    }, [data, isFetched]);

    useImperativeHandle(ref, () => ({
      reset: () => setValue(''),
    }));

    const handleSearch = (value: string) => {
      setValue(value);
    };

    const handleSelect = (value: any, option: any) => {
      const employeeByName = data.find((dt: any) => String(dt.id) === String(value));
      onSelectEmployee?.(value, employeeByName, option);
      setValue(employeeByName?.name || '');
    };

    if (!allowSearch)
      return (
        <div className="float-right mb-2">
          <Select
            onChange={handleSelect}
            placeholder={placeholder || 'Chọn nhân viên'}
            style={{ width: 350 }}
          >
            {data?.map((dt) => {
              return <Select.Option value={dt.id}>{dt.name}</Select.Option>;
            })}
          </Select>
        </div>
      );

    return (
      <AutoComplete
        dropdownMatchSelectWidth={252}
        className="w-100"
        options={options}
        allowClear
        onSelect={handleSelect}
        onClear={() => handleSelect(null, null)}
        onSearch={handleSearch}
        value={value}
      >
        <Input
          suffix={isFetching && <LoadingOutlined />}
          prefix={<SearchOutlined />}
          className="w-100"
          placeholder={placeholder || 'Tên nhân viên, email...'}
        />
      </AutoComplete>
    );
  }
);

export default EmployeeSelection;
