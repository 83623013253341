import axiosClient from '~/config/axiosClient';
import { useMutation } from 'react-query';
import { StatusOfOrder } from '~/contants/order';

interface ResetOrderArgs {
  orderCode: string;
  status: StatusOfOrder;
}

const resetOrderStatus = async ({ orderCode, status }: ResetOrderArgs) => {
  return await axiosClient.post<OrderListTypes>(`/order/resetOrderStatus`, {
    orderCode,
    status,
  });
};

export const useResetOrderStatus = () => {
  return useMutation({
    mutationFn: (param: ResetOrderArgs) => resetOrderStatus(param),
  });
};
