import React, { useCallback, useMemo } from 'react';
import { Button, Col, Row } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useOrderDetailContext } from '~/modules/operate-order/pages/OrderDetail/contexts/OrderDetailContext';
import { EMPTY } from '~/modules/operate-order/pages/OrderDetail/components/OrderDetailWrapper/OrderDetailWrapper';
import { useOrderDetailDrawerContext } from '~/modules/operate-order/pages/OrderDetail/contexts/OrderDetailDrawerContext';
import { canEditOrder } from '~/ultils/order-utils';

const DeliveryInfo: React.FC = () => {
  const { orderDetail } = useOrderDetailContext();
  const { setShowDeliveryInfo } = useOrderDetailDrawerContext();
  const deliveryAddress = orderDetail?.header?.deliveryAddress || {};

  const deliveryTimeRange = useMemo(() => {
    const startTime = orderDetail?.header?.deliveryTimeRange?.[0];
    const endTime = orderDetail?.header?.deliveryTimeRange?.[1];

    if (!startTime || !endTime) return EMPTY;
    if (moment(startTime).valueOf() === moment(endTime).valueOf())
      return moment(startTime).format('HH:mm') + ' - ';
    return moment(startTime).format('HH:mm') + ' - ' + moment(endTime).format('HH:mm DD/MM/YYYY');
  }, [orderDetail?.header?.deliveryTimeRange]);

  const handleOpenDeliveryInfo = useCallback(() => {
    setShowDeliveryInfo?.(true);
  }, []);
  return (
    <div className="mr-2">
      <div className="flex  items-center mb-2">
        <h1 className="font-bold mb-0 ">Thông tin nhận hàng </h1>
        {canEditOrder(orderDetail?.header?.status) && (
          <Button onClick={handleOpenDeliveryInfo} type="text" icon={<EditOutlined />}></Button>
        )}
      </div>
      <Row>
        <Col span={9}>
          <div className="flex flex-col">
            <span className="mr-2 font-medium" style={{ whiteSpace: 'nowrap' }}>
              Tên người nhận
            </span>
            <div className="flex items-center text-gray-600">
              <span className="mr-2">{deliveryAddress?.name || EMPTY}</span>
              <span className="mr-2">{deliveryAddress?.phone || EMPTY}</span>
            </div>
          </div>
        </Col>
        <Col span={9}>
          <div className="flex flex-col">
            <span className="mr-2 font-medium" style={{ whiteSpace: 'nowrap' }}>
              Thời gian yêu cầu giao
            </span>
            <span className="mr-2 text-gray-600">{deliveryTimeRange}</span>
          </div>
        </Col>
        <Col span={24} className="mt-3">
          <div className="flex flex-col">
            <span className="mr-2 font-medium" style={{ whiteSpace: 'nowrap' }}>
              Địa chỉ giao hàng
            </span>
            <span className="mr-2 text-gray-600">{deliveryAddress?.fullAddress || EMPTY}</span>
            {deliveryAddress?.note && <div className='flex items-center gap-1 text-orange-500'>
              <InfoCircleOutlined />
              <span className="italic">{deliveryAddress?.note}</span>
            </div>
            }
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={15} className="mt-3">
          <div className="flex flex-col">
            <span className="mr-2 font-medium" style={{ whiteSpace: 'nowrap' }}>
              Ghi chú
            </span>
            {orderDetail?.header?.note ? 
              <div className='flex justify-start gap-2 text-orange-500'>
                <div className='mt-1'><InfoCircleOutlined /></div>
                <span className="italic">{orderDetail?.header?.note}</span>
              </div> 
              : EMPTY
            }
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DeliveryInfo;
