import axiosClient from '~/config/axiosClient';
import { useQuery } from 'react-query';

const searchEmployeesByKeyword = async (params?: any) => {
  return await axiosClient.get<OrderListTypes>(`employee/searchEmployeesByKeyword`, {
    ...params,
  });
};

export const useEmployeesByKeyWordQuery = (params?: any, config = {}) => {
  return useQuery(['employees'], () => searchEmployeesByKeyword({ params }), { ...config });
};
