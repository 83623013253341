import { DeleteOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Image,
  InputNumber,
  Space,
  Table,
  Tag,
  Tooltip,
  message
} from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

import { useParams } from 'react-router-dom';
import CurrencyFormat from '~/components/commons/CurrencyFormat';
import ProductTitle from '~/components/shared/_molecules/ProductTitle';
import { SERVER_PREFIX, fallbackImage } from '~/contants/commons';
import { StatusOfOrder } from '~/contants/order';
import { useRemoveProductItem } from '~/queries/orders/useRemoveProductItem';
import { OrderDelivery, OrderDetail } from '~/types/orderDetailType';
import DeleteConfirmation from './DeleteConfirmation';
import { EMPTY } from '~/modules/operate-order/pages/OrderDetail/components/OrderDetailWrapper/OrderDetailWrapper';

const columns = ({
  onIncreaseQuantity,
  onDecreaseQuantity,
  onChangeQuantity,
  onRemoveProduct,
  onShowProductForm,
  editable,
  status,
}: any) => [
  {
    title: '',
    dataIndex: 'image',
    key: 'image',
    width: 70,
    render: (src: string) => <Image width={50} src={src || 'error'} fallback={fallbackImage} />,
  },
  {
    title: 'Sản phẩm',
    dataIndex: 'barcode',
    key: 'barcode',
    render: (text: string, product: any) => (
      <div className="flex items-center gap-4">
        <div>
          <div>{text}</div>
          <span className="text-xs">
            <ProductTitle {...product}></ProductTitle>
          </span>
        </div>
      </div>
    ),
  },
  {
    title: 'Phân loại',
    dataIndex: 'gifts',
    key: 'gifts',
    render: (gifts: any, product: any) => {
      return (
        <div className="flex">
           <div className='flex flex-wrap gap-2'>
            {product?.tags.map((tag) => (
              <Tooltip
                title={
                  <>
                    {tag === 'GIFT' && '🎁'} {tag}
                  </>
                }
              >
                <Tag
                    color="blue"
                    className="mr-0 truncate text-ellipsis"
                    style={{textAlign: 'center', maxWidth: 180 }}
                  >
                    {tag === 'GIFT' && '🎁'} {tag}
                  </Tag>
              </Tooltip>
            ))}
          </div>
        </div>
      );
    },
  },
  {
    title: 'SL đặt',
    dataIndex: 'quantity',
    key: 'quantity',
    render: (text: string, record: any) => {
      return (
        <>
          <div className="flex items-center">
            <div className="mr-1">
              {editable ? <InputNumber
                controls={false}
                onChange={(value) => onChangeQuantity?.({ barcode: record.barcode, amount: value })}
                type="number"
                className="text-center"
                size="small"
                style={{ width: '50px' }}
                value={text}
              /> : <span className="mr-2 text-gray-600">{text}</span>}
            </div>

            {editable && (
              <Button.Group className="flex flex-column">
                <Button
                  size="small"
                  onClick={() => onDecreaseQuantity?.(record.barcode)}
                  icon={<MinusOutlined />}
                />
                <Button
                  size="small"
                  onClick={() => onIncreaseQuantity?.(record.barcode)}
                  icon={<PlusOutlined />}
                />
              </Button.Group>
            )}
            <span className="ml-2">{record.unit}</span>
          </div>
          <div>
            {!Boolean(text) && <small className="text-red-500">Giá trị này phải lớn hơn 0</small>}
          </div>
        </>
      );
    },
  },
  {
    title: 'SL pick',
    dataIndex: 'pickedQuantity',
    key: 'pickedQuantity',
    width: 100,
    render: (pickedQuantity: string) => {
      return (<span className="mr-2 text-gray-600">{pickedQuantity || EMPTY}</span>);
    },
  },
  {
    title: 'Tồn kho',
    className: 'text-center',
    dataIndex: 'stockAvailable',
    key: 'stockAvailable',
    render: (stockAvailable: string, record: any) => {
      const isEmpty = !stockAvailable || !record.unit;

      return isEmpty ? <span className="mr-2 text-gray-600">{EMPTY}</span> : (<span className="mr-2 ">{stockAvailable} {record.unit}</span>);
    },
  },
  {
    title: 'Đơn giá',
    dataIndex: 'sellPrice',
    key: 'sellPrice',
    render: (sellPrice: number, record: any) => {
      const { originPrice } = record || {};
      const discount = Math.floor(
        ((Number(originPrice) - Number(sellPrice)) / Number(originPrice)) * 100
      );
      return (
        <div className="flex flex-col">
          {sellPrice === originPrice && (
            <span>
              <CurrencyFormat number={originPrice || 0} />
            </span>
          )}
          {sellPrice !== originPrice && (
            <div className="flex flex-col">
              <span
                style={{
                  textDecoration: 'line-through',
                  marginRight: '4px',
                }}
              >
                <CurrencyFormat number={originPrice || 0} />
              </span>
              <div className="flex">
                <span>
                  <CurrencyFormat number={sellPrice || 0} />
                </span>
                {Boolean(discount) && (
                  <span
                    style={{
                      marginLeft: '5px',
                    }}
                  >
                    (-{discount}%)
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
      );
    },
  },
  {
    title: 'Thành tiền',
    dataIndex: 'sellPrice',
    key: 'sellPrice',
    width: 120,
    render: (sellPrice: number, record: any) => {
      return (
        <>
          <CurrencyFormat number={sellPrice * record.quantity} />
        </>
      );
    },
  },
  {
    title: '',
    dataIndex: 'sellPrice',
    className: 'text-center',
    key: 'Action',
    width: 120,
    render: (_: number, record: any) => (
      <Space>
        {/* {editable && (
          <Button onClick={onShowProductForm} type="text" icon={<SwapOutlined />}></Button>
        )} */}
        {![
          StatusOfOrder.BOOKED_SHIPPER,
          StatusOfOrder.CANCELLED,
          StatusOfOrder.SHIPPING,
          StatusOfOrder.COMPLETED,
        ].includes(status) && (
          <Button
            onClick={() => onRemoveProduct?.(record)}
            type="text"
            icon={<DeleteOutlined />}
          ></Button>
        )}
      </Space>
    ),
  },
];

type Props = {
  onShowProductForm?: () => void;
  onIncreaseQuantity?: () => void;
  onDecreaseQuantity?: () => void;
  onChangeQuantity?: ({ barcode, amount }: { barcode: string; amount: number }) => void;
  onRemoveProduct?: (product: any) => void;
  delivery?: OrderDelivery;
  orderProducts: any;
  editable?: boolean;
  orderDetail?: OrderDetail;
};

const Coupon: React.FC<Props> = ({
  onShowProductForm,
  onIncreaseQuantity,
  onDecreaseQuantity,
  onChangeQuantity,
  onRemoveProduct,
  delivery,
  orderDetail,
  orderProducts,
  editable,
}) => {
  const queryClient = useQueryClient();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>({});


  const { code, couponId } = useParams();

  const isEditCoupon = Boolean(couponId);

  const {
    mutate: removeProduct,
    isSuccess: isSuccessRemoveProductRes,
    data: removeProductErrorRes,
    isLoading: isLoadingRemoveProduct,
  } = useRemoveProductItem();

  useEffect(() => {
    if (removeProductErrorRes?.error) {
      message.error(SERVER_PREFIX + removeProductErrorRes?.error);
    } else if (isSuccessRemoveProductRes) {
      setShowDeleteConfirmation(false);
      message.success('Xoá SP thành công');
      reloadOrderDetail();
    }
  }, [isSuccessRemoveProductRes]);

  const reloadOrderDetail = () => {
    queryClient.invalidateQueries({ queryKey: ['orderDetail'] });
  };

  const handleRemoveProduct = useCallback((product) => {
    setSelectedProduct(product);
    setShowDeleteConfirmation(true);
  }, []);

  const columnsFilter = useMemo(() => {
    const defaultColumns = columns({
      onIncreaseQuantity,
      onDecreaseQuantity,
      onChangeQuantity,
      onRemoveProduct: isEditCoupon ? onRemoveProduct : handleRemoveProduct,
      onShowProductForm,
      editable,
      isEditCoupon,
      status: orderDetail?.header?.status,
    });
    let transformColumn = [...defaultColumns];
    if (!editable) {
      transformColumn = defaultColumns.filter((col) => col.key !== 'Action');
    }
    if (
      [
        StatusOfOrder.CONFIRMED,
        StatusOfOrder.CANCELLED,
        StatusOfOrder?.COMPLETED,
        StatusOfOrder?.ERROR,
      ].includes(orderDetail?.header?.status as StatusOfOrder)
    ) {
      transformColumn = defaultColumns.filter((col) => col.key !== 'status');
    }
    return transformColumn;
  }, [editable, orderProducts]);

  const onSubmitRemove = useCallback(
    (values) => {
      removeProduct({
        orderCode: code,
        itemId: selectedProduct?.pId,
        reason: values.reason !== -1 ? values.reason : values.reasonOther,
      });
    },
    [code, removeProduct, selectedProduct]
  );

  return (
    <>

      <Table
        scroll={{ y: 300, x: 900 }}
        pagination={false}
        dataSource={orderProducts}
        columns={columnsFilter}
        rowKey={(record) => record.barcode}
      />
      <DeleteConfirmation
        onOk={onSubmitRemove}
        onCancel={() => {
          setShowDeleteConfirmation(false);
        }}
        isModalOpen={showDeleteConfirmation}
        loading={isLoadingRemoveProduct}
      />
    </>
  );
};

Coupon.defaultProps = {
  editable: true,
  orderDetail: {},
};

export default Coupon;
