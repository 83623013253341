import { useState } from 'react';

export type OrderDetailDrawerType = {
  isShowOrderInfo?: boolean;
  setShowOrderInfo?: Function;

  isShowGeneral?: boolean;
  setShowGeneral?: Function;

  isShowDeliveryInfo?: boolean;
  setShowDeliveryInfo?: Function;

  isShowEmployeeInfo?: boolean;
  setShowEmployeeInfo?: Function;

  isShowProductInfo?: boolean;
  setShowProductInfo?: Function;

  isShowOrderHistory?: boolean;
  setShowOrderHistory?: Function;

  isShowProductForm?: boolean;
  setShowProductForm?: Function;

  isShowOrderDeliveryShipping?: boolean;
  setShowOrderDeliveryShipping?: Function;

  isShowWarehouse?: boolean;
  setShowWarehouse?: Function;
};

export const useOrderDetailDrawer = () => {
  const [isShowOrderInfo, setShowOrderInfo] = useState<boolean>(false);
  const [isShowGeneral, setShowGeneral] = useState<boolean>(false);
  const [isShowDeliveryInfo, setShowDeliveryInfo] = useState<boolean>(false);
  const [isShowProductInfo, setShowProductInfo] = useState<boolean>(false);
  const [isShowOrderHistory, setShowOrderHistory] = useState<boolean>(false);
  const [isShowProductForm, setShowProductForm] = useState<boolean>(false);
  const [isShowOrderDeliveryShipping, setShowOrderDeliveryShipping] = useState<boolean>(false);
  const [isShowWarehouse, setShowWarehouse] = useState(false);

  const valueState = {
    isShowOrderInfo,
    isShowGeneral,
    isShowDeliveryInfo,
    isShowProductInfo,
    isShowOrderHistory,
    isShowProductForm,
    isShowOrderDeliveryShipping,
    isShowWarehouse,
  };

  const actions = {
    // Order info
    setShowOrderInfo,
    // Payment method
    setShowGeneral,
    // Payment channel
    //Delivery Info
    setShowDeliveryInfo,

    // Product selection
    setShowProductInfo,

    // Order history
    setShowOrderHistory,
    // Product Form Select
    setShowProductForm,
    // orderDeliveryShipping
    setShowOrderDeliveryShipping,
    // Warehouse
    setShowWarehouse,
  };

  return {
    actions,
    valueState,
  };
};
