import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Menu, message, Modal } from 'antd';
import { useQueryClient } from 'react-query';
import { SERVER_PREFIX } from '~/contants/commons';
import { ErrorOfOrder, OrderReasonCancel, RefundStatus, StatusOfOrder } from '~/contants/order';
import { useGlobalContext } from '~/contexts/GlobalContext';
import useAssignOrder from '~/hooks/useAssginOrder';
import OrderDetailAction from '~/modules/operate-order/pages/OrderDetail/components/OrderDetailAction/OrderDetailAction';
import { useOrderDetailContext } from '~/modules/operate-order/pages/OrderDetail/contexts/OrderDetailContext';
import { useOrderDetailDrawerContext } from '~/modules/operate-order/pages/OrderDetail/contexts/OrderDetailDrawerContext';
import { useOrderDetailModalContext } from '~/modules/operate-order/pages/OrderDetail/contexts/OrderDetailModalContext';
import { useCancelShipper } from '~/queries/orders/useCancelShipper';
import { useCompleteOrderMutation } from '~/queries/orders/useCompleteOrder';
import { useOrderBookShipperMutation } from '~/queries/orders/useOrderBookShipperMutation';
import { useOrderCancelMutation } from '~/queries/orders/useOrderCancelMutation';
import { useOrderConfirmMutation } from '~/queries/orders/useOrderConfirmMutation';
import { useAddOrderLogByCSCallCustomerFail } from '~/queries/orders/useAddOrderLogByCSCallCustomerFail';
import { useAllowPrintBill } from '~/queries/orders/useAllowPrintBill';
import { useUpdateOrderByPickedItems } from '~/queries/orders/useUpdateOrderByPickedItems';
import { useCreateSorryVoucherMutation } from '~/queries/orders/userCreateSorryVucherMutation';
import { useRemoveOrderError } from '~/queries/orders/useRemoveOrderError';
import { useRequestCustomerMakeExtraPayment } from '~/queries/orders/useRequestCustomerMakeExtraPayment';
import { useResetOrderStatus } from '~/queries/orders/useResetOrderStatus';
import { useSetErrorOrder } from '~/queries/orders/useSetErrorOrder';
import { useUpdateOrderVAT, VATUpdateRequest } from '~/queries/orders/useUpdateOrderVAT';
import { findConfigById } from '~/ultils/config-utils';
import { useSendZNSMessage } from '~/queries/orders/useSendZNSMessage';

const { confirm } = Modal;

const MenuAction = ({ onApplyActionOrder, orderDetail }: any) => {
  const { status, payment, incurredPayment, refund, fulfillError }: any = orderDetail?.header || {};
  const { isPaid } = payment || {};

  const isShippingFail =
    status === 'SHIPPING' && fulfillError?.type === ErrorOfOrder.ERROR_SHIPPING_NOT_DELIVERY_CUSTOMER;

  const { type } = incurredPayment || {};

  const isCompleteRefund = refund?.status === RefundStatus.COMPLETED;

  const actionOrder = useMemo(() => {
    return [
      {
        label: 'Giao cho nhân viên',
        key: 'assign-employee',
        disabled: ![StatusOfOrder.NEW].includes(status),
      },
      {
        label: 'Xác nhận đơn hàng',
        key: 'confirm-order',
        disabled: ![StatusOfOrder.ASSIGNED].includes(status),
      },
      {
        label: 'Book tài xế',
        key: 'book-logistic',
        disabled: ![StatusOfOrder.STORE_PACKED].includes(status) && !isShippingFail,
      },
      {
        label: 'Huỷ đơn hàng',
        key: 'cancel-order',
        disabled: [
          StatusOfOrder.COMPLETED,
          StatusOfOrder.CANCELLED,
          StatusOfOrder.BOOKED_SHIPPER,
        ].includes(status),
      },
      {
        label: 'Hủy Booking tài xế',
        key: 'cancel-shipper',
        disabled: ![StatusOfOrder.BOOKED_SHIPPER].includes(status),
      },
      {
        label: 'Hoàn tiền',
        key: 'refund',
        disabled:
          ![StatusOfOrder.COMPLETED, StatusOfOrder.CANCELLED].includes(status) ||
          !isPaid ||
          isCompleteRefund ||
          type === 'COD',
      },
      {
        label: 'Update theo sl đã pick',
        key: 'update-sl-picked',
        disabled: ![
          StatusOfOrder.NEW,
          StatusOfOrder.ASSIGNED,
          StatusOfOrder.STORE_PICKING,
          StatusOfOrder.STORE_PACKED,
          StatusOfOrder.CONFIRMED,
        ].includes(status),
      },
      {
        label: 'Cho phép KPOS in Bill',
        key: 'allow-kpos-print-bill',
      },
      {
        label: 'Yêu cầu thanh toán thêm',
        key: 'more-required',
        disabled: ![StatusOfOrder.STORE_PICKING, StatusOfOrder.STORE_PACKED].includes(status),
      },
      {
        label: 'Gửi sorry voucher',
        key: 'sorry-voucher',
        disabled: ![StatusOfOrder.COMPLETED, StatusOfOrder.CANCELLED].includes(status),
      },
      {
        label: 'Hoàn tất đơn hàng',
        key: 'complete-order',
        disabled: [
          StatusOfOrder.COMPLETED,
          StatusOfOrder.CANCELLED,
          StatusOfOrder.PREORDER,
        ].includes(status),
      },
      {
        label: 'Reset đơn hàng',
        key: 'reset-order',
      },
      {
        label: 'Ghi nhận liên hệ khách thất bại',
        key: 'call-customer-fail',
      },
      {
        label: 'Gửi ZNS message',
        key: 'send-zns-message',
      },
    ];
  }, [isCompleteRefund, isPaid, isShippingFail, status, type]);

  return <Menu onClick={(action) => onApplyActionOrder(action)} items={actionOrder} />;
};

const Container = () => {
  const queryClient = useQueryClient();
  const { orderDetail } = useOrderDetailContext();
  const { setShowOrderHistory } = useOrderDetailDrawerContext();

  const { onSetShowRefundForm } = useOrderDetailModalContext();

  const [reason, setReason] = useState(OrderReasonCancel.CANCEL_BY_STORE_OUT_OF_STOCK);

  const [isOpenDialogCancel, setOpenDialogCancel] = useState(false);

  const [isOpenDialogCancelShipper, setIsOpenDialogCancelShipper] = useState(false);
  const [isOpenDialogSetError, setIsOpenDialogSetError] = useState(false);
  const [isShowEmployeeAssign, setShowEmployeeAssign] = useState(false);

  const [isOpenSorryVoucher, setIsOpenSorryVoucher] = useState(false);

  const [isOpenCallCustomerFail, setIsOpenCallCustomerFail] = useState(false);

  const [isOpenSendZNSMessage, setIsOpenSendZNSMessage] = useState(false);

  const [isOpendBookShipperPopup, setIsOpendBookShipperPopup] = useState(false);

  const [isOpenVATDialog, setOpenVATDialog] = useState(false);

  const { orderStatuses } = useGlobalContext();
  const orderStatus: any = findConfigById(orderStatuses, orderDetail?.header?.status);

  const { code, fulfillError } = orderDetail?.header || {};

  const [isOpenResetOrder, setIsOpenResetOrder] = useState(false);

  const reloadOrderDetail = () => {
    queryClient.invalidateQueries({ queryKey: ['orderDetail'] });
  };

  // book tài xế

  const {
    mutate: bookShipper,
    isSuccess: isSuccessBookShipper,
    isLoading: loadingBookShipper,
    data: dataBookShipper,
  } = useOrderBookShipperMutation();

  const handleBookShipper = useCallback(
    (values) => {
      bookShipper({
        orderCode: code,
        ...values,
      });
    },
    [bookShipper, code]
  );

  const handleCloseBookShipperDialog = useCallback(() => {
    setIsOpendBookShipperPopup(false);
  }, []);

  useEffect(() => {
    if (dataBookShipper?.error) {
      message.error(SERVER_PREFIX + dataBookShipper?.error);
    } else if (isSuccessBookShipper) {
      message.success('Book tài xế thành công');
      setIsOpendBookShipperPopup(false);
      reloadOrderDetail();
    }
  }, [isSuccessBookShipper]);

  // Xác nhận đơn hàng
  const {
    mutate: orderConfirm,
    isSuccess: isSuccessConfirm,
    data: dataConfirm,
  } = useOrderConfirmMutation();

  useEffect(() => {
    if (dataConfirm?.error) {
      message.error(SERVER_PREFIX + dataConfirm?.error);
    } else if (isSuccessConfirm) {
      message.success('Xác nhận đơn hàng thành công');
      reloadOrderDetail();
    }
  }, [isSuccessConfirm]);

  // Assign to me
  function onAssignSuccess() {
    reloadOrderDetail();
  }

  const { onAssignEmployee } = useAssignOrder({
    rowSelected: [orderDetail?.header?.code],
    onAssignSuccess,
  });

  // gia cho nhân viên

  const handleAssign = useCallback(
    (value?: string, option?: any) => {
      onAssignEmployee?.({ assigneeId: value, option, rowSelected: [code] });
      setShowEmployeeAssign?.(false);
    },
    [code]
  );

  // Huỷ đơn hàng
  const {
    mutate: cancelOrder,
    isSuccess: isSuccessCancel,
    isLoading: loadingCancel,
    data: dataCancel,
  } = useOrderCancelMutation();

  useEffect(() => {
    if (dataCancel?.error) {
      message.error(SERVER_PREFIX + dataCancel?.error);
    } else if (isSuccessCancel) {
      message.success('Huỷ đơn hàng thành công');
      setOpenDialogCancel(false);
      reloadOrderDetail();
    }
  }, [isSuccessCancel]);

  // remove error

  const {
    mutate: removeOrderError,
    isSuccess: isSuccessRemoveOrderErrorRes,
    data: removeOrderErrorRes,
  } = useRemoveOrderError();

  useEffect(() => {
    if (removeOrderErrorRes?.error) {
      message.error(SERVER_PREFIX + removeOrderErrorRes?.error);
    } else if (isSuccessRemoveOrderErrorRes) {
      message.success('Gỡ lỗi đơn hàng thành công');
      reloadOrderDetail();
    }
  }, [removeOrderErrorRes]);

  // gỡ lỗi
  const {
    mutate: setOrderError,
    isSuccess: isSuccessSetOrderError,
    isLoading: loadingSetError,
    data: setOrderErrorRes,
  } = useSetErrorOrder();

  useEffect(() => {
    if (setOrderErrorRes?.error) {
      message.error(SERVER_PREFIX + setOrderErrorRes?.error);
    } else if (isSuccessSetOrderError) {
      message.success('Gán lỗi đơn hàng thành công');
      reloadOrderDetail();
    }
  }, [setOrderErrorRes, isSuccessSetOrderError]);

  // Huỷ book tài xế
  const {
    mutate: cancelShipper,
    isSuccess: isSuccessCancelShipper,
    data: cancelShipperRes,
  } = useCancelShipper();

  useEffect(() => {
    if (cancelShipperRes?.error) {
      message.error(SERVER_PREFIX + cancelShipperRes?.error);
    } else if (isSuccessCancelShipper) {
      message.success('Huỷ booking tài xế đơn hàng thành công');
      setIsOpenDialogCancelShipper(false);
      reloadOrderDetail();
    }
  }, [cancelShipperRes, isSuccessCancelShipper]);

  const {
    mutate: updateOrderByPickedItems,
    isSuccess: isSuccessUpdateOrderByPickedItems,
    data: dataOrderByPickedItems,
  } = useUpdateOrderByPickedItems();

  useEffect(() => {
    if (dataOrderByPickedItems?.error) {
      message.error(SERVER_PREFIX + dataOrderByPickedItems?.error);
    } else if (isSuccessUpdateOrderByPickedItems) {
      message.success('Cập nhập thành công');
      reloadOrderDetail();
    }
  }, [isSuccessUpdateOrderByPickedItems]);

  // allow print bill

  const {
    mutate: allowPrintBill,
    isSuccess: isSuccessAllowPrintBill,
    data: dataAllowPrintBill,
  } = useAllowPrintBill();

  useEffect(() => {
    if (dataAllowPrintBill?.error) {
      message.error(SERVER_PREFIX + dataAllowPrintBill?.error);
    } else if (isSuccessAllowPrintBill) {
      message.success('Cho phép KPOS in Bill thành công');
      reloadOrderDetail();
    }
  }, [isSuccessAllowPrintBill]);
  
  
  

  // Sorry vouher
  // gỡ lỗi
  const {
    mutate: createSorryVoucher,
    isSuccess: isSuccessCreateSorryVoucher,
    isLoading: loadingSorryVoucher,
    data: sorryVoucherRes,
  } = useCreateSorryVoucherMutation();

  useEffect(() => {
    if (sorryVoucherRes?.error) {
      message.error(SERVER_PREFIX + sorryVoucherRes?.error);
    } else if (isSuccessCreateSorryVoucher) {
      message.success('Tạo sorry voucher thành công');
      setIsOpenSorryVoucher(false);
      reloadOrderDetail();
    }
  }, [sorryVoucherRes, isSuccessCreateSorryVoucher]);

  const handleCreateSorryVoucher = useCallback(
    (value) => {
      if (value.reason === -1) {
        createSorryVoucher({ ...value, reason: value.desc, orderCode: code });
      } else {
        createSorryVoucher({ ...value, orderCode: code });
      }
    },
    [code, createSorryVoucher]
  );

  const handleCloseSorryVoucherDialog = useCallback(() => {
    setIsOpenSorryVoucher(false);
  }, []);

  // Complete order
  const {
    mutate: onCompleteOrder,
    isSuccess: isSuccessCompleteOrder,
    data: completeOrderRes,
  } = useCompleteOrderMutation();

  useEffect(() => {
    if (completeOrderRes?.error) {
      message.error(SERVER_PREFIX + completeOrderRes?.error);
    } else if (isSuccessCompleteOrder) {
      message.success('Hoàn tất đơn hàng thành công');
      reloadOrderDetail();
    }
  }, [completeOrderRes]);

  // request customer pay more
  const {
    mutate: onRequestCustomerMakeExtraPayment,
    isSuccess: isSuccessRequestCustomerMakeExtraPayment,
    data: requestCustomerMakeExtraPaymentRes,
  } = useRequestCustomerMakeExtraPayment();

  useEffect(() => {
    if (requestCustomerMakeExtraPaymentRes?.error) {
      message.error(SERVER_PREFIX + requestCustomerMakeExtraPaymentRes?.error);
    } else if (isSuccessRequestCustomerMakeExtraPayment) {
      message.success('Yêu cầu thanh toán thêm thành công');
      reloadOrderDetail();
    }
  }, [requestCustomerMakeExtraPaymentRes]);

  const messageUpdateSLPicked = useMemo(() => {
    if (fulfillError?.type === 'ERROR_STORE_PICKED_INCORRECT_ORDER')
      return 'Bạn có muốn chuyển đơn hàng sang trạng thái đã pick và update số lượng mặt hàng theo KDB-APP-Pick';
    return 'Bạn có muốn chuyển đơn hàng sang trạng thái đã pick';
  }, [fulfillError]);

  // VAT

  const {
    mutate: updateVAT,
    isSuccess: isSuccessUpdateVAT,
    data: vatUpdateRes,
    isLoading: isLoadingUpdateVAT,
  } = useUpdateOrderVAT();

  const handleUpdateOrderVAT = useCallback(
    (values: VATUpdateRequest) => {
      updateVAT({ ...values, orderCode: code });
    },
    [code, updateVAT]
  );

  useEffect(() => {
    if (vatUpdateRes?.error) {
      message.error(SERVER_PREFIX + vatUpdateRes?.error);
    } else if (isSuccessUpdateVAT) {
      message.success('Cập nhập VAT thành công');
      setOpenVATDialog(false);
      reloadOrderDetail();
    }
  }, [vatUpdateRes]);

  const {
    mutate: addOrderLogByCSCallCustomerFail,
    isSuccess: isSuccessAddOrderLogByCSCallCustomerFail,
    isLoading: isLoadingAddOrderLogByCSCallCustomerFail,
    data: addOrderLogByCSCallCustomerFailRes,
  } = useAddOrderLogByCSCallCustomerFail();

  useEffect(() => {
    if (addOrderLogByCSCallCustomerFailRes?.error) {
      message.error(SERVER_PREFIX + addOrderLogByCSCallCustomerFailRes?.error);
    } else if (isSuccessAddOrderLogByCSCallCustomerFail) {
      message.success('Thêm log thành công');
      setIsOpenCallCustomerFail(false);
      reloadOrderDetail();
    }
  }, [isSuccessAddOrderLogByCSCallCustomerFail]);

  const handleAddOrderLogByCSCallCustomerFail = useCallback(({note}) => {
    addOrderLogByCSCallCustomerFail({orderCode: code, note});
  }, [code, addOrderLogByCSCallCustomerFail]);

  const handleCloseVATDialog = useCallback(() => {
    setOpenVATDialog(false);
  }, []);

  const handleOpenVATDialog = useCallback(() => {
    setOpenVATDialog(true);
  }, []);

  const handleApplyActionOrder = useCallback(
    (action: any) => {
      const { key } = action;

      switch (key) {
        case 'assign-to-me':
          return onAssignEmployee?.({ rowSelected: [String(code)] });
        case 'assign-employee':
          return setShowEmployeeAssign(true);
        case 'confirm-order':
          return confirmActionOrder(
            'Bạn có muốn xác nhận đơn hàng này không?',
            function handleOk() {
              orderConfirm(code as string);
            }
          );
        case 'book-logistic':
          setIsOpendBookShipperPopup(true);
          break;
        case 'refund':
          onSetShowRefundForm?.(true);
          break;
        case 'cancel-order':
          setOpenDialogCancel(true);
          break;
        case 'remove-error':
          return confirmActionOrder('Bạn có muốn gỡ lỗi đơn hàng này?', function handleOk() {
            removeOrderError(code);
          });
        case 'cancel-shipper':
          setIsOpenDialogCancelShipper(true);
          break;
        case 'set-error':
          return setIsOpenDialogSetError(true);

        case 'update-sl-picked':
          return confirmActionOrder(messageUpdateSLPicked, function handleOk() {
            updateOrderByPickedItems(code);
          });

        case 'allow-kpos-print-bill':
          return confirmActionOrder('Bạn có muốn cho phép KPOS in Bill?', function handleOk() {
            allowPrintBill(code);
          });

        case 'more-required':
          return confirmActionOrder('Bạn có muốn yêu cầu thanh toán thêm?', function handleOk() {
            onRequestCustomerMakeExtraPayment({ orderCode: code });
          });
        case 'sorry-voucher':
          setIsOpenSorryVoucher(true);
          break;
        case 'complete-order':
          return confirmActionOrder('Bạn có muốn hoàn tất đơn hàng này?', function handleOk() {
            onCompleteOrder({ orderCode: code });
          });
        case 'reset-order':
          setIsOpenResetOrder(true);
          return;

        case 'call-customer-fail':
          setIsOpenCallCustomerFail(true);
          return;
        case 'send-zns-message':
          setIsOpenSendZNSMessage(true);
          return;
        default:
          throw new Error("Can't find action");
      }
    },
    [allowPrintBill, code, messageUpdateSLPicked, onAssignEmployee, onCompleteOrder, onRequestCustomerMakeExtraPayment, onSetShowRefundForm, orderConfirm, removeOrderError, updateOrderByPickedItems]
  );

  const confirmActionOrder = (
    confirmText: string,
    onPressOk?: VoidFunction,
    onPressCancel?: VoidFunction
  ) => {
    confirm({
      centered: true,
      icon: (
        <div className="text-center">
          <ExclamationCircleOutlined type="warning" style={{ fontSize: 30, color: 'orange' }} />
        </div>
      ),
      content: <div className="text-center">{confirmText}</div>,
      okText: 'Xác nhận',
      cancelText: 'Trở lại',
      onOk() {
        onPressOk?.();
      },
      onCancel() {
        onPressCancel?.();
      },
    } as any);
  };

  const handleShowOrderHistory = useCallback(() => {
    setShowOrderHistory?.(true);
  }, []);

  const handleCloseCancelOrder = useCallback(() => {
    setOpenDialogCancel(false);
  }, []);

  const handleCancelOrder = useCallback(
    (value: any) => {
      const jsonSubmit = {
        orderCode: code,
        cancelDesc: value?.cancelDesc,
        cancelCode: value?.cancelCode,
      };
      cancelOrder({
        ...jsonSubmit,
      });
    },
    [cancelOrder, code]
  );

  const handleChangeReasonCancel = useCallback((value: any) => {
    setReason(value);
  }, []);

  const handleCloseCancelShipper = useCallback(() => {
    setIsOpenDialogCancelShipper(false);
  }, []);

  const handleCancelShipper = useCallback(
    (value: any) => {
      cancelShipper({
        orderCode: code,
        cancelReason: value,
      });
    },
    [cancelShipper, code]
  );

  // reset order

  const {
    mutate: resetOrder,
    isSuccess: isSuccessResetOrder,
    data: resetOrderRes,
    isLoading: isLoandingResetOrder,
  } = useResetOrderStatus();

  useEffect(() => {
    if (resetOrderRes?.error) {
      message.error(SERVER_PREFIX + resetOrderRes?.error);
    } else if (isSuccessResetOrder) {
      message.success('Reset đơn hàng thành công');
      reloadOrderDetail();
      setIsOpenResetOrder(false);
    }
  }, [resetOrderRes]);

  const handleResetOrder = (values) => {
    resetOrder({
      ...values,
      orderCode: code,
    });
  };

  const handleCloseResetOrder = () => {
    setIsOpenResetOrder(false);
  };

  // send zns message

  const {
    mutate: sendZNSMessage,
    isSuccess: isSuccessSendZNSMessage,
    data: sendZNSMessageRes,
    isLoading: isLoadingSendZNSMessage,
  } = useSendZNSMessage();

  const handleSendZNSMessage = (values: any) => {
    sendZNSMessage({
      ...values,
      orderCode: code,
    });
  };

  useEffect(() => {
    if (sendZNSMessageRes?.error) {
      message.error(SERVER_PREFIX + sendZNSMessageRes?.error);
    } else if (isSuccessSendZNSMessage) {
      message.success('Gửi ZNS message thành công');
      setIsOpenSendZNSMessage(false);
      reloadOrderDetail();
    }
  }, [sendZNSMessageRes]);

  const composeProps = {
    orderDetail,
    orderStatus,

    isOpenDialogCancel,

    isShowEmployeeAssign,
    isOpenDialogSetError,
    loadingBookShipper,
    loadingSetError,
    loadingCancel,
    onAssignOrder: handleAssign,
    onShowEmployeeAssign: () => setShowEmployeeAssign(false),
    MenuAction,
    onShowOrderHistory: handleShowOrderHistory,
    onApplyActionOrder: handleApplyActionOrder,
    onCancelOrder: handleCancelOrder,
    onCloseCancelOrder: handleCloseCancelOrder,
    onChangeReasonCancel: handleChangeReasonCancel,

    onCloseCancelShipper: handleCloseCancelShipper,
    onCancelShipper: handleCancelShipper,
    isOpenDialogCancelShipper,

    isOpendBookShipperPopup,
    onBookShipper: handleBookShipper,
    onCloseBookShipperDialog: handleCloseBookShipperDialog,

    isOpenVATDialog,
    onUpdateOrderVAT: handleUpdateOrderVAT,
    onCloseVATDialog: handleCloseVATDialog,
    onOpenVATDialog: handleOpenVATDialog,
    isLoadingUpdateVAT,

    isOpenSorryVoucher,
    loadingSorryVoucher,
    onCreateSorryVoucher: handleCreateSorryVoucher,
    onCloseSorryVoucherDialog: handleCloseSorryVoucherDialog,

    onResetOrder: handleResetOrder,
    onCloseResetOrder: handleCloseResetOrder,
    isOpenResetOrder,
    isLoandingResetOrder,

    onCloseCallCustomerFail: () => setIsOpenCallCustomerFail(false),
    onAddOrderLogByCSCallCustomerFail: handleAddOrderLogByCSCallCustomerFail,
    isOpenCallCustomerFail,
    isLoadingAddOrderLogByCSCallCustomerFail,

    isOpenSendZNSMessage,
    onSendZNSMessage: handleSendZNSMessage,
    onCloseSendZNSMessage: () => setIsOpenSendZNSMessage(false),
    isLoadingSendZNSMessage,
    isSuccessSendZNSMessage,

  };

  return <OrderDetailAction {...composeProps} />;
};

export default Container;
