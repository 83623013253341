import { useMutation } from 'react-query';
import axiosClient from '~/config/axiosClient';

export type CancelShipperMutation = {
  orderCode: string;
  cancelReason: string;
};

const cancelShipper = async ({ orderCode, cancelReason }: CancelShipperMutation) => {
  return await axiosClient.post<OrderListTypes>(`order/cancelBookShipper`, {
    orderCode,
    cancelReason,
  });
};

export const useCancelShipper = () => {
  return useMutation({
    mutationFn: (cancelShipperMutation: CancelShipperMutation) =>
      cancelShipper(cancelShipperMutation),
  });
};
