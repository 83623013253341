import { useCallback, useEffect, useState } from 'react';
import { useOrderDetailContext } from '../contexts/OrderDetailContext';

const useOrderProductDetail = () => {
  const { orderDetail } = useOrderDetailContext();
  const [products, setProducts] = useState<any>();

  useEffect(() => {
    setProducts(orderDetail?.delivery?.productItems || []);
  }, [orderDetail]);

  const increaseQuantity = useCallback(
    (barcode: string) => {
      const productIndex = products.findIndex((pr: any) => pr.barcode === barcode);
      if (productIndex !== -1) {
        products[productIndex].quantity += 1;
        setProducts([...products]);
      }
    },
    [products]
  );

  const decreaseQuantity = useCallback(
    (barcode: string) => {
      const productIndex = products.findIndex((pr: any) => pr.barcode === barcode);
      if (products[productIndex].quantity <= 0) return;
      if (productIndex !== -1) {
        products[productIndex].quantity -= 1;
        setProducts([...products]);
      }
    },
    [products]
  );

  const handleChangeQuantity = useCallback(
    (barcode: string, value: any) => {
      const productIndex = products.findIndex((pr: any) => pr.barcode === barcode);
      if (productIndex !== -1) {
        products[productIndex].quantity = Number(value);
        setProducts([...products]);
      }
    },
    [products]
  );

  return {
    products,
    setProducts,
    increaseQuantity,
    decreaseQuantity,
    onChangeQuantity: handleChangeQuantity,
  };
};
export default useOrderProductDetail;
