import { EditOutlined, SwapOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SCard } from '~/components/commons';
import { OrderDelivery, OrderDetail } from '~/types/orderDetailType';
import { BaseUtils } from '~/ultils/base-utils';
import { findConfigById } from '~/ultils/config-utils';
import { canEditOrder } from '~/ultils/order-utils';
import { useOrderDetailDrawerContext } from '../../../contexts/OrderDetailDrawerContext';

type Iprops = {
  delivery?: OrderDelivery;
  providerName?: any;
  fullAddressStore?: string;
  orderDetail?: OrderDetail;
};

const MoreInfoOrder: React.FC<Iprops> = ({
  delivery,
  orderDetail,
}) => {
  const navigate = useNavigate();

  const { setShowWarehouse } = useOrderDetailDrawerContext();

  const handleOpenWareHouse = () => {
    setShowWarehouse?.(true);
  };

  const canEdit = canEditOrder(orderDetail?.header?.status);

  const onEditCoupon = () => {
    navigate(`/order/order-detail/edit-delivery/${orderDetail?.header?.code}`);
  };

  return (
    <div className="mt-5">
      <SCard rounded className="justify-center">
        <Row align="middle" justify="space-between" gutter={15}>
          <Col md={1} xs={24}>
            <div className="flex items-start flex-no-wrap">
              {canEdit && (
                <Button
                    style={{ marginTop: -4 }}
                    onClick={onEditCoupon}
                    type="text"
                    className="text-blue-500"
                    icon={<EditOutlined />}
                >Thay đổi sản phẩm</Button>
              )}
            </div>
          </Col>
          <Col >
            <div>
              <Button
                icon={<SwapOutlined />}
                disabled={!canEdit}
                type="link"
                onClick={handleOpenWareHouse}
              >
                  {delivery?.storeCode ? <span>{delivery?.storeCode} - {findConfigById(BaseUtils.getConfigWindow()?.stores, delivery?.storeCode)}</span> : <span className="ml-2">Chọn kho xuất hàng</span>}
                </Button>
              </div>
          </Col>
        </Row>
      </SCard>
    </div>
  );
};

export default MoreInfoOrder;
