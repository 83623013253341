import { CloudDownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { Badge, Button, DatePicker, Input, PageHeader, Tag, message } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import IPagination from '~/components/antd/IPagination';
import TableAntCustom from '~/components/antd/ITable/TableAntCustom';
import { SCard } from '~/components/commons';
import OrderPayment from '~/components/commons/OrderPayment';
import CustomerLink from '~/components/shared/_molecules/CustomerLink';
import { SERVER_PREFIX } from '~/contants/commons';
import { RefundStatus, RefundStatusLabel, mappingLabelOperaOrder } from '~/contants/order';
import { usePaging } from '~/hooks/usePaging';
import { useExportOrderRefund } from '~/queries/orders/refund/useExportOrderRefund';
import { useOrderRefundStatusCountersQuery } from '~/queries/orders/useOrderRefundStatusCountersQuery';
import { useOrdersQuery } from '~/queries/orders/useOrdersQuery';
import { BaseUtils } from '~/ultils/base-utils';
import { findConfigById } from '~/ultils/config-utils';

const { RangePicker } = DatePicker;

const columns = [
  {
    title: 'Mã đơn hàng',
    dataIndex: 'code',
    key: 'code',
    render: (code: string, record: any) => {
      return (
        <div className="flex flex-col">
          <Link target={'_blank'} to={`/order/order-detail/${code}`}>
            {code}
          </Link>
          <span className="text-gray-500">
            {moment(record?.orderTime).format('DD/MM/YYYY HH:mm')}
          </span>
        </div>
      );
    },
  },
  {
    title: 'Khách hàng',
    dataIndex: 'customer',
    key: 'customer',
    render: (customer: any) => {
      return (
        <>
          <div>
            <CustomerLink customer={customer} />
          </div>
          <span style={{ color: 'rgb(131 125 125)', fontSize: '12px' }} className="text-xs ">
            {customer.phone}
          </span>
        </>
      );
    },
  },
  {
    title: 'NV xử lý',
    dataIndex: 'assignee',
    key: 'assignee',
    render: (assignee: any) => (
      <>
        <span>{assignee?.name || '--'}</span>
        <br />
        <span style={{ color: 'rgb(131 125 125)' }} className="text-xs ">
          {assignee?.phone}
        </span>
      </>
    ),
  },
  {
    title: 'Số hoá đơn KDB',
    dataIndex: 'invoiceCode',
    key: 'invoiceCode',
    render: (invoiceCode: string) => {
      return <span>{invoiceCode}</span>;
    },
  },
  {
    title: 'Số tiền cần hoàn',
    dataIndex: 'refund',
    key: 'refund',
    render: (refund: TemplateOrderRefund) => {
      return <span>{BaseUtils.formatNumber(refund?.cashAmount || 0)}đ</span>;
    },
  },
  {
    title: 'Thanh toán',
    dataIndex: 'payment',
    key: 'payment',
    render: (payment: any, record: any) => {
      return <OrderPayment amount={record?.amount} payment={payment} />;
    },
  },
  {
    title: 'Trạng thái',
    dataIndex: 'error',
    key: 'error',

    render: (error, record) => {
      const statusName: any = findConfigById(
        BaseUtils.getConfigWindow()?.orderStatuses,
        record?.status
      );
      // findConfigById(orderStatuses, orderDetail?.header?.status);
      return (
        <div className="flex flex-col gap-1">
          <span className="text-red-500">{error?.name}</span>
          <div>
            <Badge
              className="whitespace-no-wrap"
              color={mappingLabelOperaOrder?.[record?.status]?.color || '#dfdfdf'}
              text={statusName || 'Không xác định'}
            />
          </div>
          <span style={{ color: 'rgb(131 125 125)' }} className="text-xs">
            {record?.lastTimeUpdateStatus && moment(record?.lastTimeUpdateStatus).fromNow()}
          </span>
        </div>
      );
    },
  },
  {
    title: 'Mô tả',
    dataIndex: 'refund',
    key: 'refund',
    render: (refund) => {
      return <span>{refund?.desc}</span>;
    },
  },
];

const ListBill = () => {
  const [currentTab, setCurrentTab] = useState(RefundStatus.INITIALIZED);

  const [keyword, setKeyword] = useState('');
  const [timeRange, setTimeRange] = useState('');

  const filters = {
    refundStatus: currentTab,
    keyword,
    refundCreatedTimeRange: timeRange,
  };

  const {
    isFetching: loadingExportOrder,
    data: exportOrderRes,
    refetch: exportOrder,
    isSuccess: isExportOrderSuccess,
  } = useExportOrderRefund(filters);

  useEffect(() => {
    if (!isExportOrderSuccess) return;
    if (exportOrderRes?.error) {
      message.error(SERVER_PREFIX + exportOrderRes?.error);
    } else {
      window.open(exportOrderRes);
      message.success('Xuất file excel thành công');
    }
  }, [exportOrderRes, isExportOrderSuccess]);

  const {
    actions: { onChangePagination, setPage },
    states: { sizer, currentPage },
  } = usePaging({ sizer: 10 });

  const { data: response, isLoading: loadingOrder } = useOrdersQuery({
    pageIndex: currentPage,
    limit: sizer,
    refundStatus: currentTab,
    keyword,
    refundCreatedTimeRange: timeRange,
  });

  const { data: counterRefund } = useOrderRefundStatusCountersQuery();

  const dataOrder = response?.list || [];
  return (
    <>
      <div className="my-3">
        <Button
          onClick={() => {
            setCurrentTab(RefundStatus.INITIALIZED);
            setPage(1);
          }}
          shape="round"
          type={currentTab === RefundStatus.INITIALIZED ? 'primary' : 'default'}
          className="text-left mr-4"
          size="middle"
        >
          <div className="flex items-center">
            <span className="mr-2">{RefundStatusLabel.INITIALIZED}</span>
            <Tag className="px-3 mr-0 border rounded-full" color="blue">
              <span>{BaseUtils.formatNumber(counterRefund?.['INITIALIZED'] || 0)}</span>
            </Tag>
          </div>
        </Button>
        <Button
          onClick={() => {
            setCurrentTab(RefundStatus.PROCESSING);
            setPage(1);
          }}
          shape="round"
          type={currentTab === RefundStatus.PROCESSING ? 'primary' : 'default'}
          className="text-left mr-4"
          size="middle"
        >
          <div className="flex items-center">
            <span className="mr-2">{RefundStatusLabel.PROCESSING}</span>
            <Tag className="px-3 mr-0 border rounded-full" color="blue">
              <span>{BaseUtils.formatNumber(counterRefund?.['PROCESSING'] || 0)}</span>
            </Tag>
          </div>
        </Button>
        <Button
          onClick={() => {
            setCurrentTab(RefundStatus.COMPLETED);
            setPage(1);
          }}
          shape="round"
          type={currentTab === RefundStatus.COMPLETED ? 'primary' : 'default'}
          className="text-left"
          size="middle"
        >
          <div className="flex items-center">
            <span className="mr-2">{RefundStatusLabel.COMPLETED}</span>
            <Tag className="px-3 mr-0 border rounded-full" color="green">
              <span>{BaseUtils.formatNumber(counterRefund?.['COMPLETED'] || 0)}</span>
            </Tag>
          </div>
        </Button>
      </div>
      <SCard className="p-5" rounded>
        <div className="mb-4 flex justify-between gap-4">
          <div>
            <Input
              allowClear
              onChange={(e: any) => {
                setKeyword(e.target.value);
              }}
              style={{ width: 400 }}
              suffix={<SearchOutlined />}
              className="mr-3"
              placeholder="Tìm theo mã đơn hàng, tên khách hàng, SDT"
            />
            <RangePicker
              format="DD-MM-YYYY"
              placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
              onChange={(dates: any) => {
                if (!dates) {
                  return setTimeRange('');
                }
                const startDateTransform = moment(
                  moment(Number(moment(dates[0]).valueOf())).startOf('day')
                ).valueOf();
                const endDateTransform = moment(
                  moment(Number(moment(dates[1]).valueOf())).endOf('day')
                ).valueOf();
                setTimeRange(startDateTransform + '-' + endDateTransform);
              }}
            />
          </div>
          <Button
            icon={<CloudDownloadOutlined />}
            loading={loadingExportOrder}
            onClick={exportOrder}
          >
            Xuất file excel
          </Button>
        </div>
        <TableAntCustom
          iKey="code"
          dataSource={dataOrder || []}
          columns={columns}
          loading={loadingOrder}
          pagination={false}
        ></TableAntCustom>
        <IPagination
          hiddenPerPage
          size={sizer}
          total={response?.total || 0}
          current={currentPage}
          onChange={onChangePagination}
        />
      </SCard>
    </>
  );
};

export default ListBill;
