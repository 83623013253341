import axiosClient from '~/config/axiosClient';
import { useMutation } from 'react-query';
import { ErrorOfOrder } from '~/contants/order';

export type OrderErrorMutation = {
  orderCode?: string;
  errorCode?: ErrorOfOrder;
  errorDesc?: string;
};

const setOrderError = async ({
  errorCode = ErrorOfOrder.ERROR_STORE_OUT_OF_STOCK,
  orderCode,
  errorDesc,
}: OrderErrorMutation) => {
  return await axiosClient.post<OrderListTypes>(`/order/setOrderError`, {
    errorCode,
    orderCode,
    errorDesc,
  });
};

export const useSetErrorOrder = () => {
  return useMutation({
    mutationFn: (orderErrorMutation: Partial<OrderErrorMutation>) =>
      setOrderError(orderErrorMutation),
  });
};
