import { ExclamationCircleOutlined } from '@ant-design/icons';
import { message, Modal } from 'antd';
import { useCallback, useEffect } from 'react';
import { SERVER_PREFIX } from '~/contants/commons';
import { useOrdersAssignMutation } from '~/queries/orders/useOrderAssignMutation';

const { confirm } = Modal;

const useAssignOrder = ({ onAssignSuccess, onAssignFailure }: any) => {
  const {
    isLoading: loadingAssign,
    data: assignResponse,
    mutate: assignOrder,
    isSuccess: assignSuccess,
  } = useOrdersAssignMutation();

  useEffect(() => {
    if (!assignSuccess) return;
    if (assignResponse?.error) {
      message.error(SERVER_PREFIX + assignResponse?.error);
      onAssignFailure?.();
    } else {
      message.success('Đã phân công thành công');
      onAssignSuccess?.();
    }
  }, [assignResponse, assignSuccess]);

  const handleAssignEmployee = useCallback(
    ({
      assigneeId,
      option,
      rowSelected,
    }: {
      assigneeId?: any;
      option?: any;
      rowSelected?: any;
    }) => {
      confirm({
        centered: true,
        icon: (
          <div className="text-center">
            <ExclamationCircleOutlined type="warning" style={{ fontSize: 30, color: 'orange' }} />
          </div>
        ),
        content: (
          <div className="text-center">
            {!assigneeId ? (
              <div className="flex flex-col">
                <strong>Xác nhận giao cho tôi</strong>
                <span>Bạn có muốn phân công các đơn này?</span>
              </div>
            ) : (
              <span>
                Bạn có muốn gán cho <b>{option?.label}</b> không?
              </span>
            )}
          </div>
        ),
        okText: <span>Xác nhận</span>,
        cancelText: 'Trở lại',
        onOk() {
          const objSubmitAssign = {
            orderCodes: [...(rowSelected as Array<string>)],
            assigneeId,
          };
          if (!assigneeId) {
            delete objSubmitAssign.assigneeId;
          }
          assignOrder({ ...objSubmitAssign });
        },
      } as any);
    },
    []
  );

  return {
    onAssignEmployee: handleAssignEmployee,
    loadingAssign,
    assignResponse,
    assignSuccess,
  };
};

export default useAssignOrder;
