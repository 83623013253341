import { useMutation } from 'react-query';
import axiosClient from '~/config/axiosClient';

export type RequestSorryVoucher = {
  quantity: number; // Số lượng voucher
  orderCode: string;
};

const createSorryVoucher = async (sorryVoucher: RequestSorryVoucher) => {
  return await axiosClient.post<any>(`order/createSorryVoucher`, {
    ...sorryVoucher,
  });
};

export const useCreateSorryVoucherMutation = () => {
  return useMutation({
    mutationFn: (sorryVoucher: RequestSorryVoucher) => createSorryVoucher(sorryVoucher),
  });
};
