import { FieldTimeOutlined, InboxOutlined, InfoCircleOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Button, Col, Row, Tag, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import CurrencyFormat from '~/components/commons/CurrencyFormat/CurrencyFormat';
import CustomerRank from '~/components/commons/CustomerRank';
import CustomerLink from '~/components/shared/_molecules/CustomerLink/CustomerLink';
import { EMPTY } from '~/modules/operate-order/pages/OrderDetail/components/OrderDetailWrapper/OrderDetailWrapper';
import { useOrderDetailContext } from '~/modules/operate-order/pages/OrderDetail/contexts/OrderDetailContext';
import { BaseUtils } from '~/ultils/base-utils';
;

const OrderInfo: React.FC = () => {
  const { orderDetail } = useOrderDetailContext();

  return (
    <>
      <div className="flex items-center justify-between mb-3 ">
        <div className="flex gap-2 items-center">
          <h1 className="font-bold mb-0">Thông tin đặt hàng </h1>
          {orderDetail?.header?.isExistCallLog && (
            <Link
              target={'_blank'}
              to={`/customer/call-history?phone=${orderDetail?.header?.customer?.phone}`}
            >
              <Button size="small" type="link" icon={<FieldTimeOutlined />}>
                Lịch sử cuộc gọi
              </Button>
            </Link>
          )}
        </div>
      </div>
      <Row gutter={[10, 10]}>
        <Col md={11} xs={24}>
          <div className="flex flex-col">
            <div className='flex items-center'>
              <span className="mr-2 font-medium">Khách hàng</span>
              {orderDetail?.header?.customer?.membership?.rank && (
                <CustomerRank rank={orderDetail?.header?.customer?.membership?.rank} />
              )}
            </div>
            <div className='flex items-center'>
              <div className="mr-2 flex align-center mt-2">
                {orderDetail?.header?.customer?.name && (
                  // <Space direction="vertical">
                    <div className="flex gap-x-2 gap-y-1 flex-wrap">
                      <span>
                        <CustomerLink customer={orderDetail?.header?.customer as any} />
                      </span>
                      <span className="text-blue-500">
                        {orderDetail?.header?.customer?.phone || EMPTY}{' '}
                      </span>
                      <Tooltip title="Số đơn đã hoàn thành">
                        <Tag className='self-start cursor-pointer mr-0' color="blue">
                          <ShoppingCartOutlined />
                          <span>{BaseUtils.formatNumber(orderDetail?.header?.customer?.numberCompletedOrders || 0)}</span>
                        </Tag>
                      </Tooltip>
                      <Tooltip title="Số đơn đang xử lý">
                        <Tag className='self-start cursor-pointer' color="blue">
                          <InboxOutlined />
                          <span>{BaseUtils.formatNumber(orderDetail?.header?.customer?.numberInProcessOrders || 0)}</span>
                        </Tag>
                      </Tooltip>
                    </div>
                  // </Space>
                )}
              </div>
            </div>
          </div>
        </Col>
        <Col md={13} xs={24}>
          <Row gutter={[15, 15]}>
            <Col md={8} xs={12}>
              <div className="flex flex-col">
                <span className="mr-2 font-medium">Đã chi</span>
                <span className="text-gray-600">
                  <CurrencyFormat number={orderDetail?.header?.customer?.membership?.spend || 0} />
                </span>
              </div>
            </Col>
            <Col md={8} xs={12}>
              <div className="flex flex-col">
                <span className="mr-2 font-medium">Điểm thưởng</span>
                <span className="text-gray-600">
                  {BaseUtils.formatNumber(orderDetail?.header?.customer?.membership?.points) ||
                    EMPTY}
                </span>
              </div>
            </Col>
            <Col md={8} xs={12}>
              <div className="flex flex-col">
                <span className="mr-2 font-medium">Tổng số đơn</span>
                <span className="text-gray-600">
                  {BaseUtils.formatNumber(Number(orderDetail?.header?.customer?.numberCompletedOrders))} đơn
                </span>
              </div>
            </Col>
          </Row>
        </Col>
        {orderDetail?.header?.customer?.note && (
          <Col xs={24}>
            <div className='flex gap-1 text-orange-500'>
              <div className='mt-1'><InfoCircleOutlined /></div>
              <span className="italic">{orderDetail?.header?.customer?.note}</span>
            </div>
          </Col>
        )}
      </Row>
    </>
  );
};

export default OrderInfo;
