import { AppstoreOutlined, CaretDownOutlined, FieldTimeOutlined } from '@ant-design/icons';
import { Button, Card, Dropdown, Menu, Skeleton, Tag } from 'antd';
import moment from 'moment-timezone';
import { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createBreakpoint } from 'react-use';
import { StatusOfOrderText } from '~/contants/order';
import { useGlobalContext } from '~/contexts/GlobalContext';
import { useGetOrderStatusCounters } from '~/queries/orders/useOrderStatusCounters';
import { BaseUtils } from '~/ultils/base-utils';
import { findConfigById } from '~/ultils/config-utils';

export const DEFAULT_CHANNEL = ['ONELIFE_WEB', 'ONELIFE_APP'];

const useBreakpoint = createBreakpoint();

const StatusStatistics = ({ staticDashboard, isFetchingStatusCounter }) => {
  const navigate = useNavigate();

  const goOrderList = (status) => {
    if (status) {
      navigate(`/order/list-order?status=${status}&today=1&saleChannel=ONE_LIFE`);
    } else {
      navigate(`/order/list-order?today=1&saleChannel=ONE_LIFE`);
    }
  };

  if (isFetchingStatusCounter) {
    return (
      <div className="flex gap-5">
        <Skeleton round active style={{ width: '120px' }} paragraph={false} />
        <Skeleton round active style={{ width: '120px' }} paragraph={false} />
        <Skeleton round active style={{ width: '130px' }} paragraph={false} />
        <Skeleton round active style={{ width: '110px' }} paragraph={false} />
        <Skeleton round active style={{ width: '100px' }} paragraph={false} />
        <Skeleton round active style={{ width: '100px' }} paragraph={false} />
        <Skeleton round active style={{ width: '120px' }} paragraph={false} />
      </div>
    );
  }
  return (
    <div className="flex gap-2 flex-wrap">
      <Tag
        onClick={() => goOrderList(undefined)}
        className="px-3 mr-0 border rounded-full cursor-pointer"
        color="blue"
      >
        <span className="mr-2">Tất cả</span>
        <span>{BaseUtils.formatNumber(staticDashboard?.ALL || 0)}</span>
      </Tag>
      <Tag
        onClick={() => goOrderList('NEW')}
        className="px-3 mr-0 border rounded-full cursor-pointer"
        color="blue"
      >
        <span className="mr-2">{StatusOfOrderText['NEW']}</span>
        <span>{BaseUtils.formatNumber(staticDashboard?.NEW || 0)}</span>
      </Tag>
      <Tag
        onClick={() => goOrderList('STORE_PACKED')}
        className="px-3 mr-0 border rounded-full cursor-pointer"
        color="blue"
      >
        <span className="mr-2">{StatusOfOrderText['STORE_PACKED']}</span>
        <span>{BaseUtils.formatNumber(staticDashboard?.STORE_PACKED || 0)}</span>
      </Tag>
      <Tag
        onClick={() => goOrderList('SHIPPING')}
        className="px-3 mr-0 border rounded-full cursor-pointer"
        color="blue"
      >
        <span className="mr-2">{StatusOfOrderText['SHIPPING']}</span>
        <span>{BaseUtils.formatNumber(staticDashboard?.SHIPPING || 0)}</span>
      </Tag>
      <Tag
        onClick={() => goOrderList('COMPLETED')}
        className="px-3 mr-0 border rounded-full cursor-pointer"
        color="blue"
      >
        <span className="mr-2">{StatusOfOrderText['COMPLETED']}</span>
        <span>{BaseUtils.formatNumber(staticDashboard?.COMPLETED || 0)}</span>
      </Tag>
      <Tag
        onClick={() => goOrderList('CANCELLED')}
        className="px-3 mr-0 border rounded-full cursor-pointer"
        color="orange"
      >
        <span className="mr-2">{StatusOfOrderText['CANCELLED']}</span>
        <span>{BaseUtils.formatNumber(staticDashboard?.CANCELLED || 0)}</span>
      </Tag>
      <Tag
        onClick={() => goOrderList('FULLFIL_ERROR')}
        className="px-3 mr-0 border rounded-full cursor-pointer"
        color="red"
      >
        <span className="mr-2">{StatusOfOrderText['FULLFIL_ERROR']}</span>
        <span>{BaseUtils.formatNumber(staticDashboard?.FULLFIL_ERROR || 0)}</span>
      </Tag>
      <Tag
        onClick={() => goOrderList('OVERDUE_SLA')}
        className="px-3 mr-0 border rounded-full cursor-pointer"
        color="red"
      >
        <span className="mr-2">{StatusOfOrderText['OVERDUE_SLA']}</span>
        <span>{BaseUtils.formatNumber(staticDashboard?.OVERDUE_SLA || 0)}</span>
      </Tag>
    </div>
  );
};

const DashBoardOrders = () => {
  const breakpoint = useBreakpoint();

  const startDateTodayTransform = moment(moment(new Date()).startOf('day')).valueOf();
  const endDateTodayTransform = moment(moment(new Date()).endOf('day')).valueOf();

  const startDateYesterDayTransform = moment(moment().subtract(1, 'days').startOf('day')).valueOf();
  const endDateYesterDayTransform = moment(moment().subtract(1, 'days').endOf('day')).valueOf();

  const { saleChannels, saleChannel, tenant, setSaleChannel} = useGlobalContext();

  const [selectedTime, setSelectedTime] = useState(1);
  
  const { operationType} = useGlobalContext();
  

  const {
    data: staticDashboard,
    isFetching: isFetchingStatusCounter,
    refetch: getStaticOrders,
  } = useGetOrderStatusCounters({
    saleChannels: saleChannel,
    tenant,
    operationType: operationType,
    orderTimeRange:
      selectedTime !== 1
        ? `${startDateYesterDayTransform}-${endDateYesterDayTransform}`
        : `${startDateTodayTransform}-${endDateTodayTransform}`,
  });

  useEffect(() => {
    getStaticOrders();
  }, [getStaticOrders, selectedTime]);

  const handleSaleChannelSelected = (value: any) => {
    setSaleChannel(value);
  }

  const isTablet = breakpoint === 'tablet';

  return (
    <>
      <div className="mt-2">
        <Card
          bodyStyle={{ padding: 0 }}
          headStyle={{
            paddingLeft: isTablet ? 10 : 24,
            paddingRight: isTablet ? 10 : 24,
          }}
          bordered={false}
          title={
            <>
              <div className="flex items-center gap-1">
                <div>
                  <Dropdown
                    overlayStyle={{ width: 150 }}
                    placement="bottomCenter"
                    overlay={<Menu>
                      <Menu.Item key="1" onClick={() => setSelectedTime(1)}>Đơn hôm nay</Menu.Item>
                      <Menu.Item key="2" onClick={() => setSelectedTime(2)}>Đơn hôm qua</Menu.Item>
                    </Menu>}
                  >
                    <Button
                      shape="round"
                      type="text"
                      size={'small'}
                    >
                      <div className="flex items-center gap-1">
                        <FieldTimeOutlined />
                        <div style={{ width: 90 }}>
                          {selectedTime === 1 ? 'Đơn hôm nay' : 'Đơn hôm qua'}
                        </div>
                        <CaretDownOutlined />
                      </div>

                    </Button>
                  </Dropdown>
                </div>
                <div>
                {/* <Dropdown
                  overlayStyle={{ width: 150 }}
                  placement="bottomCenter"
                  overlay={<Menu>
                    <Menu.Item key="1" onClick={() => handleSaleChannelSelected(null)}>Tất cả</Menu.Item>
                    {saleChannels?.map(({ name, id }) => (
                      <Menu.Item key={id} onClick={() => handleSaleChannelSelected([id])}>{name}</Menu.Item>
                    ))}     
                    <Menu.Item key="1" onClick={() => handleSaleChannelSelected(DEFAULT_CHANNEL)}>OneLife App/Web</Menu.Item>               
                  </Menu>}
                >
                  <Button
                    shape="round"
                    type="text"
                    size={'small'}
                  >
                    <div className="flex items-center gap-1">
                      
                      <AppstoreOutlined />
                      <div style={{ width: 120 }}>
                        {JSON.stringify(saleChannel) === JSON.stringify(DEFAULT_CHANNEL) ? 'OneLife App/Web' : findConfigById(BaseUtils.getConfigWindow()?.saleChannels, saleChannel?.[0] as any) || 'Tất cả'}
                      </div>
                      <CaretDownOutlined />
                    </div>

                  </Button>
                </Dropdown> */}
              </div>
            </div>
            </>
          }
          extra={
            <div className="flex gap-5 items-center">
              <div className="flex gap-3"></div>
              {!isTablet && (
                <StatusStatistics
                  isFetchingStatusCounter={isFetchingStatusCounter}
                  staticDashboard={staticDashboard}
                />
              )}
            </div>
          }
        >
          {isTablet && (
            <div className="mt-2 mx-2 mb-3 flex justify-center">
              <StatusStatistics
                isFetchingStatusCounter={isFetchingStatusCounter}
                staticDashboard={staticDashboard}
              />
            </div>
          )}
        </Card>
      </div>
    </>
  );
};

export default memo(DashBoardOrders);
