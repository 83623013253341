import React from 'react';
import { Drawer, DrawerProps } from 'antd';
import EmployeeSelection from '../EmployeeSelection';

type Props = {
  onClose?: () => void;
  onSelectEmployee?: (value: any, option?: any) => void;
  visible?: boolean;
} & DrawerProps;

const EmployeeDrawer: React.FC<Props> = ({ onClose, onSelectEmployee, visible, ...restProps }) => {
  return (
    <>
      <Drawer
        title="Giao cho nhân viên"
        placement={'right'}
        width={550}
        onClose={onClose}
        visible={visible}
        key={'shiping'}
        {...restProps}
      >
        {visible && <EmployeeSelection onSelectEmployee={onSelectEmployee} />}
      </Drawer>
    </>
  );
};

export default React.memo(EmployeeDrawer);
