import { EditOutlined } from '@ant-design/icons';
import { Button, Col, Row, Tag, Tooltip } from 'antd';
import React, { useCallback } from 'react';
import CurrencyFormat from '~/components/commons/CurrencyFormat';
import { EMPTY } from '~/modules/operate-order/pages/OrderDetail/components/OrderDetailWrapper/OrderDetailWrapper';
import { useOrderDetailContext } from '~/modules/operate-order/pages/OrderDetail/contexts/OrderDetailContext';
import { useOrderDetailDrawerContext } from '~/modules/operate-order/pages/OrderDetail/contexts/OrderDetailDrawerContext';
import { BaseUtils } from '~/ultils/base-utils';
import { canEditOrder } from '~/ultils/order-utils';

const OrderDeliveryShipping: React.FC = () => {
  const { orderDetail } = useOrderDetailContext();
  const { groupShippingCode, groupShippingOrderCodes, groupShippingTotalCODAmount } = orderDetail?.header || {};
  const { setShowOrderDeliveryShipping } = useOrderDetailDrawerContext();
  const { serviceName, trackingNumber, trackingLink, packageSizeName, driverName, driverPhone, fee } =
    orderDetail?.header?.shipping || {};

  console.log('orderDetail?.header?.shipping', orderDetail?.header?.shipping);

  const handleOpenDeliveryInfo = useCallback(() => {
    setShowOrderDeliveryShipping?.(true);
  }, [setShowOrderDeliveryShipping]);

  return (
    <div className="mr-2">
      <div className="flex  items-center mb-2">
        <h1 className="font-bold mb-0 ">Thông tin Shipping </h1>
        {canEditOrder(orderDetail?.header?.status) && (
          <Button onClick={handleOpenDeliveryInfo} type="text" icon={<EditOutlined />}></Button>
        )}
      </div>
      <Row gutter={[15, 15]}>
        <Col md={8} xs={12}>
          <div className="flex flex-col">
            <span className="mr-2 font-medium" style={{ whiteSpace: 'nowrap' }}>
              Package size
            </span>
            {packageSizeName && <span className="mr-2 text-gray-600">{packageSizeName || EMPTY}</span>}
          </div>
        </Col>
        <Col md={16} xs={12}>
          <div className="flex flex-col">
            <span className="mr-2 font-medium" style={{ whiteSpace: 'nowrap' }}>
              Service
            </span>
            <span className="mr-2 text-gray-600">{serviceName || EMPTY}</span>
          </div>
        </Col>
        <Col md={8} xs={12}>
          <div className="flex flex-col">
          <span className="mr-2 font-medium" style={{ whiteSpace: 'nowrap' }}>
              Mã vận đơn
            </span>
            {trackingNumber ? <Tooltip placement="bottom" title={'Xem lộ trình đơn hàng'}>
              <a href={trackingLink} target="_blank" rel="noreferrer" className="text-blue-500">
                #{trackingNumber || EMPTY}
              </a>
            </Tooltip> : <span className="mr-2 text-gray-600">{EMPTY}</span>}
          </div>
        </Col>
        <Col md={9} xs={12}>
          <div className="flex flex-col">
          <span className="mr-2 font-medium" style={{ whiteSpace: 'nowrap' }}>
              Tài xế
            </span>
            {driverName && driverPhone ? <span className="mr-2 text-gray-600">{driverName} - {driverPhone}</span> : <span className="mr-2 text-gray-600">{EMPTY}</span>}
          </div>
        </Col>
        <Col md={3} xs={12}>
          <div className="flex flex-col">
          <span className="mr-2 font-medium" style={{ whiteSpace: 'nowrap' }}>
              Cước vận chuyển
            </span>
            {fee ? <span className="mr-2 text-gray-600">{BaseUtils.formatNumeric(fee || 0)} đ</span> : <span className="mr-2 text-gray-600">{EMPTY}</span>}
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
      {groupShippingCode && <>
          <Col md={24} xs={12}>
            <div className="flex flex-col">
              <div className="flex flex-col">
                <span className="mr-2 font-medium" style={{ whiteSpace: 'nowrap' }}>
                  Group Shipping <Tag color="blue">{groupShippingCode} </Tag>
                  {
                    groupShippingTotalCODAmount && groupShippingTotalCODAmount > 0 && (
                      <Tag color="blue" style={{ marginLeft: 5 }}>
                        COD <CurrencyFormat number={groupShippingTotalCODAmount || 0} />
                      </Tag>
                    )
                  }
                </span>
                <div className='mt-1'>
                  <span className='text-blue-500'>{groupShippingOrderCodes?.join(', ')}</span>
                </div>
            </div>
            </div>
          </Col>
        </>}
      </Row>
    </div>
  );
};

export default OrderDeliveryShipping;
