import { Modal } from 'antd';
import TableAntCustom from '~/components/antd/ITable/TableAntCustom';

const { confirm } = Modal;

const columns = [
  {
    title: 'Barcode',
    dataIndex: 'barcode',
    key: 'barcode',
  },
  {
    title: 'Tên SP',
    dataIndex: 'productName',
    key: 'productName',
  },
  {
    title: 'SL SP đã đặt',
    dataIndex: 'quantityOrdered',
    key: 'quantityOrdered',
    render: (quantityOrdered: number, record: any) => {
      return <span>{quantityOrdered} {record?.unit?.name}</span> 
    },
  },
];

function VariantDistributions({ variantDistributions, totalNumberOrders }: any) {
  return (
    <div>
      <TableAntCustom
        pagination={false}
        loading={false}
        columns={columns}
        scroll={{ x: 1000, y: 300 }}
        dataSource={variantDistributions}
      />
    </div>
  );
}

export default VariantDistributions;
