import { EditOutlined, FieldTimeOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space, Tag } from 'antd';
import moment from 'moment';
import React from 'react';
import { SCard } from '~/components/commons';
import { useGlobalContext } from '~/contexts/GlobalContext';
import { EMPTY } from '~/modules/operate-order/pages/OrderDetail/components/OrderDetailWrapper/OrderDetailWrapper';
import { useOrderDetailContext } from '~/modules/operate-order/pages/OrderDetail/contexts/OrderDetailContext';
import { useOrderDetailDrawerContext } from '~/modules/operate-order/pages/OrderDetail/contexts/OrderDetailDrawerContext';
import { findConfigById } from '~/ultils/config-utils';
import { canEditOrder } from '~/ultils/order-utils';

const GeneralOrderInfo: React.FC = () => {
  const { saleChannels, orderDeliveryTypes} = useGlobalContext();
  const { orderDetail } = useOrderDetailContext();
  const { header } = orderDetail || {};
  const { saleChannel, payment } = header || {};

  const { setShowGeneral } = useOrderDetailDrawerContext();
  const deliveryTypeName = findConfigById(orderDeliveryTypes, orderDetail?.header?.deliveryType);

  const handleOpenPaymentMethod = () => {
    setShowGeneral?.(true);
  };

  return (
    <SCard>
      <h1 className="font-bold mb-3">
        Thông tin chung
        {canEditOrder(orderDetail?.header?.status) && (
          <Button onClick={handleOpenPaymentMethod} type="text" icon={<EditOutlined />}></Button>
        )}
      </h1>
      <Row>
        <Col span={12}>
          <div className="flex flex-col">
            <span className="mr-2 font-medium" style={{ whiteSpace: 'nowrap' }}>
              Ngày đặt
            </span>
            <span className="mr-2 flex items-center">
              <FieldTimeOutlined style={{ color: 'var(--primary-color)' }} />
              <span className="ml-1 font-medium text-gray-500">
                {moment(orderDetail?.header?.orderTime).format('DD/MM/YYYY HH:mm:ss')}
              </span>
            </span>
          </div>
        </Col>
        <Col span={12}>
          <div className="flex flex-col">
            <span className="mr-2 font-medium" style={{ whiteSpace: 'nowrap' }}>
              Kênh bán hàng
            </span>
            <Space size="small">
              {saleChannel && (
                <span>
                  {findConfigById(saleChannels, saleChannel)}
                </span>
              )}
            </Space>
          </div>
        </Col>
        <Col span={12}>
          <div className="flex flex-col mt-2">
            <span className="mr-2 font-medium" style={{ whiteSpace: 'nowrap' }}>
              Hình thức thanh toán
            </span>
            <div>
              <span className="flex">
                <Tag color="blue">{payment?.methodName || EMPTY}</Tag>
              </span>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className="flex flex-col mt-2">
            <span className="mr-2 font-medium" style={{ whiteSpace: 'nowrap' }}>
              Loại đơn
            </span>
            <div>
              <Tag color="blue">{deliveryTypeName}</Tag>
            </div>
          </div>
        </Col>
      </Row>
    </SCard>
  );
};

export default GeneralOrderInfo;
