import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SCard } from '~/components/commons';
import Coupon from '~/components/shared/Coupon';
import { useGlobalContext } from '~/contexts/GlobalContext';
import { findConfigById } from '~/ultils/config-utils';
import { useOrderDetailContext } from '~/modules/operate-order/pages/OrderDetail/contexts/OrderDetailContext';
import MoreInfoOrder from '~/modules/operate-order/pages/OrderDetail/components/OrderDetailWrapper/components/MoreInfoOrder';

const CouponOrder: React.FC = () => {
  const navigate = useNavigate();
  const { orderDetail } = useOrderDetailContext();

  const { delivery, header } = orderDetail || {};

  const handleEditDelivery = () => {
    navigate(`/order/order-detail/edit-delivery/${header?.code}`);
  };
  const { shippingProviders } = useGlobalContext();
  const providerName = (provider: any) => findConfigById(shippingProviders, provider);

  return (
    <div>
      <SCard className="mt-3">
        <div className="mb-5 mt-3">
          <MoreInfoOrder
            delivery={delivery}
            providerName={providerName(header?.shipping?.provider)}
            orderDetail={orderDetail}
          />
        </div>
        <Coupon
          onShowProductForm={handleEditDelivery}
          orderDetail={orderDetail}
          orderProducts={delivery?.productItems}
          delivery={delivery}
          editable={false}
        />
      </SCard>
    </div>
  );
};

export default CouponOrder;
